import React, { PureComponent, Fragment } from 'react';
import i18next from 'i18next';
import { productTypes, productTypeTranslations } from 'shared/constants';
import RaidixLicensesPage from 'components/pages/licenses/raidix-licenses-page';
import EraLicensesPage from 'components/pages/licenses/era-licenses-page';
import { Tab, TabList, TabPanel } from 'react-tabs';
import FetchData from 'components/fetch-data';
import MemorizeTabs from 'components/controls/memorize-tabs';

export default class Licenses extends PureComponent {
  getTabName(productType) {
    return i18next.t(productTypeTranslations[productType]);
  }

  getTabs() {
    const {
      licensesDataForTableGetSignal,
      eraLicensesDataForTableGetSignal,
      functionalitiesDataGetSignal,
      licenseSubtypesDataGetSignal,
      productVersionsDataGetSignal,
      eraLicensesDataIm,
    } = this.props;

    return [
      {
        productType: productTypes.RAIDIX,
        fetchProps: {
          licensesDataForTableGetSignal,
          functionalitiesDataGetSignal,
          licenseSubtypesDataGetSignal,
          fetchActionNames: [
            'licensesDataForTableGetSignal',
            'functionalitiesDataGetSignal',
            'licenseSubtypesDataGetSignal',
          ],
        },
        component: RaidixLicensesPage,
      },
      {
        productType: productTypes.ERA,
        fetchProps: {
          eraLicensesDataForTableGetSignal,
          functionalitiesDataGetSignal,
          licenseSubtypesDataGetSignal,
          productVersionsDataGetSignal,
          fetchActionNames: [
            'eraLicensesDataForTableGetSignal',
            'productVersionsDataGetSignal',
          ],
        },
        component: EraLicensesPage,
        componentProps: {
          licensesDataIm: eraLicensesDataIm,
        }
      },
    ];
  }

  getAvailableTabs() {
    const productCount = this.props.licensesInfoDataIm.getIn(['data', 'productCount']);

    return this.getTabs().filter(({ productType }) => {
      const productInfo = productCount.find(
        product => product.get('productType') === productType && product.get('count') > 0
      );

      return productInfo !== undefined;
    });
  }

  render() {
    const availableTabs = this.getAvailableTabs();
    const hasTabs = availableTabs.length > 0;

    return (
      <Fragment>
        <div className="content__head">
          <h1 className="content__title">{i18next.t('licenses')}</h1>
        </div>
        {
          hasTabs ? (
            <MemorizeTabs name="order-license-tabs">
              <TabList>
                {availableTabs.map(({ productType }) => (
                  <Tab key={productType}>{this.getTabName(productType)}</Tab>
                ))}
              </TabList>

              {availableTabs.map(({ productType, fetchProps, component: TabComponent, componentProps }) => (
                <TabPanel key={productType}>
                  <FetchData {...fetchProps}>
                    <TabComponent
                      {...this.props}
                      productType={productType}
                      tableId={`${productType}-licenses`}
                      {...componentProps}
                    />
                  </FetchData>
                </TabPanel>
              ))}
            </MemorizeTabs>
          ) : (
            <div className="empty-table">
              <div className="empty-table__title">{i18next.t('no_data_here')}</div>
            </div>
          )
        }
      </Fragment>
    );
  }
}
