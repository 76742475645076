import { connect } from 'react-redux';
import Personalisation from 'components/pages/personalisation';
import dataFetcherEnhance from 'components/data-fetcher-enhance';
import { actions as usersDataActions } from 'ducks/data/users';

const mapDispatchToProps = Object.assign(
  {},
  usersDataActions,
);


function mapStateToProps(state) {
  return {
    authDataIm: state.data.authDataIm,
    usersDataIm: state.data.usersDataIm,
    fetchActionNames: [
      'usersDataGetSignal',
    ],
  };
}

const EditUserWithFetch = dataFetcherEnhance(Personalisation);

export default connect(mapStateToProps, mapDispatchToProps)(EditUserWithFetch);
