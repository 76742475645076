import React, { Component } from 'react';
import { languageTranslations } from 'constants';
import classnames from 'classnames';
import i18next from 'i18next';

export default class Language extends Component {
  constructor(props) {
    super(props);

    this.onSetLanguage = this.onSetLanguage.bind(this);
  }

  onSetLanguage() {
    this.props.setLanguageDelta(this.props.languageCode);
  }

  render() {
    const { languageCode, currentLanguage } = this.props;
    const active = languageCode === currentLanguage;

    return (
      <div className="user-menu__language">
        <button
          className={classnames('button button_language', {
            'button_action button_non-interactive': active,
          })}
          onClick={!active ? this.onSetLanguage : null}
          tabIndex={active ? -1 : null}
          title={i18next.t(languageTranslations[languageCode])}
        >
          {languageCode}
        </button>
      </div>
    );
  }
}
