import React, { PureComponent, Fragment } from 'react';
import { Form } from 'containers';
import i18next from 'i18next';
import PersonalisationForm from 'components/form/personalisation';

export default class Personalisation extends PureComponent {
  render() {
    const {
      authDataIm,
      usersDataIm,
      usersDataUpdateSignal,
      match,
    } = this.props;

    if (!match) {
      return null;
    }

    const userId = authDataIm.getIn(['data', 'id']);
    const userLogin = authDataIm.getIn(['data', 'login']);
    const userIm = usersDataIm.get('data').find(model => model.get('id') === userId);

    return (
      <Fragment>
        <div className="content__head">
          <h1 className="content__title">
            {i18next.t('edit_profile')}
            <span className="content__subtitle"> {userLogin}</span>
          </h1>
        </div>
        <div className="content__body">
          <Form id="add-user">
            <PersonalisationForm
              userIm={userIm}
              usersDataIm={usersDataIm}
              submitSignal={usersDataUpdateSignal}
              isEdit
            />
          </Form>
        </div>
      </Fragment>
    );
  }
}
