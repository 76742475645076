import React, { PureComponent } from 'react';
import classnames from 'classnames';
import TableCell from './cell';

export default class TableHeader extends PureComponent {
  getContent(cell) {
    const {
      tableId,
      sortChangeAction,
      currentSort,
      isEmpty,
    } = this.props;

    let className = 'table__header-text';
    let onClick;


    // Если колонку можно сортировать и в таблице есть данные для сортировки
    if (cell.sort && !isEmpty) {
      let currentOrder = '';
      let currentField = '';
      let order = 'asc';
      let sortArrow = '';

      className += ' table__header-text_sorted';

      if (currentSort !== undefined) {
        currentOrder = currentSort.get('order');
        currentField = currentSort.get('field');
      }

      if (currentField === cell.sort.field) {
        order = currentOrder === 'asc' ? 'desc' : 'asc';
        sortArrow = order === 'asc' ?
          ' table__header-text_sorted_asc' :
          ' table__header-text_sorted_desc';
      }

      className += sortArrow;
      onClick = () => {
        sortChangeAction(tableId, {
          field: cell.sort.field,
          type: cell.sort.type,
          order,
        });
      };
    }

    const title = cell.name;
    const text = cell.name;

    return <span className={className} title={title} onClick={onClick}>{text}</span>;
  }

  render() {
    const { cells } = this.props;

    return (
      <div className="table__row table__row_header">
        {cells.map(cell => (
          <TableCell
            key={cell.id}
            className={classnames('table__cell', 'table__cell_header', cell.className)}
            value={this.getContent(cell)}
          />
        ))}
      </div>
    );
  }
}
