import { Map, fromJS } from 'immutable';
import { applicationName } from 'config';
import { fetchSignal } from 'ducks/fetch';

/*
* Constants
* */

export const MODULE_NAME = 'era-licenses-data';
export const URL = '/api/era-licenses';
const URL_TABLE = '/api/era-licenses/table';
const URL_HISTORY = '/api/era-licenses/history';
const URL_DEACTIVATE = `${URL}/deactivate`;

// Action names
const FETCH_DONE = `${applicationName}/${MODULE_NAME}/FETCH_DONE`;
const RESET = `${applicationName}/${MODULE_NAME}/RESET`;
const UPDATE_TOTAL_COUNT = `${applicationName}/${MODULE_NAME}/UPDATE_TOTAL_COUNT`;
const FETCH_DONE_BY_ORDER = `${applicationName}/${MODULE_NAME}/FETCH_DONE_BY_ORDER`;
const FETCH_DONE_BY_IDS = `${applicationName}/${MODULE_NAME}/FETCH_DONE_BY_IDS`;
const UPDATE_ALL_LICESNSES_COUNT = `${applicationName}/${MODULE_NAME}/UPDATE_ALL_LICESNSES_COUNT`;

/*
* Reducer
* */

const initialState = Map({
  data: false,
  totalCount: null,
  allLicensesCount: null,
  dataByOrder: false,
  dataOriginalLicenses: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DONE:
      return state.set('data', fromJS(action.payload));

    case UPDATE_TOTAL_COUNT:
      return state.set('totalCount', action.payload);

    case UPDATE_ALL_LICESNSES_COUNT:
      return state.set('allLicensesCount', action.payload);

    case FETCH_DONE_BY_ORDER:
      return state.set('dataByOrder', fromJS(action.payload));

    case FETCH_DONE_BY_IDS:
      return state.set('dataOriginalLicenses', fromJS(action.payload));

    case RESET:
      return initialState;

    default:
      return state;
  }
}

/*
* Actions
* */

const eraLicensesDataResetDelta = () => ({ type: RESET });

const eraLicensesDataForTableGetSignal = ({
  page,
  pageSize,
  sort,
  filters,
  isUnmodified,
  originalLicenseIds,
} = {}) => dispatch =>
  Promise.coroutine(function* getLicense() {
    const data = {
      page: page || 0,
      pageSize: pageSize || 10,
      isUnmodified,
      originalLicenseIds
    };

    if (sort) {
      data.sort = sort;
    }

    if (filters) {
      data.filter = filters;
    }

    const answer = yield dispatch(fetchSignal(URL_TABLE, { method: 'PATCH', body: data }));

    if (answer.isSuccess) {
      dispatch({ type: FETCH_DONE, payload: answer.data.licenses });
      dispatch({ type: UPDATE_TOTAL_COUNT, payload: answer.data.totalCount });
      dispatch({ type: UPDATE_ALL_LICESNSES_COUNT, payload: answer.data.allLicensesCount });
    }

    return answer;
  })();


/**
 * Fetch history chunk sorted licenses by filter
 */
// Запрос для порционного получения лицензий (необходим для таблиц с ассинхронной фильтрацией)
const eraLicensesHistoryDataGetSignal = ({ page, pageSize, sort, id } = {}) => dispatch =>
  Promise.coroutine(function* getLicense() {
    const data = {
      page: page || 0,
      pageSize: pageSize || 10,
      id
    };

    if (sort) {
      data.sort = sort;
    }

    const answer = yield dispatch(fetchSignal(URL_HISTORY, { method: 'PATCH', body: data }));

    if (answer.isSuccess) {
      dispatch({ type: FETCH_DONE, payload: answer.data.licenses });
      dispatch({ type: UPDATE_TOTAL_COUNT, payload: answer.data.totalCount });
    }

    return answer;
  })();

const eraLicensesForOrderGetSignal = ({ id } = {}) => dispatch =>
  Promise.coroutine(function* getLicense() {
    const url = `${URL}?orderId=${id}`;
    const answer = yield dispatch(fetchSignal(url));

    if (answer.isSuccess) {
      dispatch({ type: FETCH_DONE_BY_ORDER, payload: answer.data.licenses });
      dispatch({ type: FETCH_DONE_BY_IDS, payload: answer.data.originalLicenses });
    }

    return answer;
  })();

// Используется для деактивации ERA лицензии по ID
const eraLicensesDataDeactivateUpdateSignal = ({ id, orderId }) => dispatch =>
  Promise.coroutine(function* deactive() {
    const answer = yield dispatch(fetchSignal(URL_DEACTIVATE, { method: 'PATCH', body: { id, orderId } }));

    if (answer.isSuccess) {
      dispatch({ type: FETCH_DONE_BY_ORDER, payload: answer.data.licenses });
      dispatch({ type: FETCH_DONE_BY_IDS, payload: answer.data.originalLicenses });
    }

    return answer;
  })();

export const actions = {
  eraLicensesDataResetDelta,
  eraLicensesDataForTableGetSignal,
  eraLicensesHistoryDataGetSignal,
  eraLicensesForOrderGetSignal,
  eraLicensesDataDeactivateUpdateSignal,
};
