import React from 'react';
import i18next from 'i18next';
import Select from 'react-select';
import { ITEMS_PER_PAGE_OPTIONS } from 'constants';

export default function ItemsPerPage({ itemsPerPage, itemsCount, onChange }) {
  const itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS.filter(value => (value < itemsCount));
  const options = itemsPerPageOptions.map(value => ({ value, label: value }));

  options.push({
    value: itemsCount,
    label: i18next.t('all'),
  });

  return (
    <div className="table__pagination-per-page">
      <div className="table__pagination-per-page-title">{i18next.t('rows_per_page')}:</div>
      <div className="table__pagination-per-page-value">
        <Select
          name="form-field-name"
          value={itemsPerPage}
          onChange={onChange}
          options={options}
          clearable={false}
          searchable={false}
        />
      </div>
    </div>
  );
}
