/* global document */
import React, { PureComponent } from 'react';
import Tooltip from 'components/tooltip';

export default class PopupMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onKeyup = this.onKeyup.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keyup', this.onKeyup, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeyup, false);
  }

  onKeyup(event) {
    if (event.keyCode === 27 && !this.tooltipRef.isHidden) {
      this.tooltipRef.toggleHide();
    }
  }

  onClick() {
    this.tooltipRef.toggleHide();
  }

  render() {
    return (
      <Tooltip
        {...this.props}
        ref={(ref) => { this.tooltipRef = ref; }}
        onClickContent={this.onClick}
      />
    );
  }
}
