import React, { PureComponent, Fragment } from 'react';
import classnames from 'classnames';
import PopupMenu from 'components/popup-menu';
import ExpandMoreIcon from 'img/expand-more.svg';

export default class DropdownButton extends PureComponent {
  render() {
    const { children, popUpMenu, onClick, icon: Icon } = this.props;

    return (
      <div className="button-tooltip">
        <button
          type="button"
          className={classnames('button button_split', { button_icon: Icon })}
          onClick={onClick}
        >
          {
            Icon ? (
              <Fragment>
                <span className="button__icon">
                  <Icon
                    width="16"
                    height="16"
                  />
                </span>
                <span className="button__text">
                  {children}
                </span>
              </Fragment>
            ) : ({ children })
          }
        </button>
        <PopupMenu
          content={popUpMenu}
          classNameButtonShow="button_show-tooltip-toggle"
        >
          <button
            type="button"
            className="button button_tooltip-toggle button_icon"
          >
            <span className="button__icon button__icon_no-margin">
              <ExpandMoreIcon
                width="24"
                height="24"
              />
            </span>
          </button>
        </PopupMenu>
      </div>
    );
  }
}
