import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import i18next from 'i18next';
import Select from 'react-select';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
);

const getSelectOptions = () => [
  { label: i18next.t('all'), value: 'all' },
  { label: 'SC/DC', value: 'SCDC' },
];

const getCounterByLabel = (label, licenses) => {
  const temp = {};

  if (!temp[label]) {
    const license = licenses.find(lic => lic.label === label);
    temp[label] = [license.counterSingle, license.counterDC];
  }

  return temp[label];
};

const getDatasets = (licenses) => {
  const labels = [];
  const dataAll = [];
  const dataSingle = [];
  const dataDC = [];
  const count = {
    all: 0,
    single: 0,
    dc: 0,
  };

  licenses.forEach((lic) => {
    labels.push(lic.label);
    dataAll.push(lic.counter);
    dataSingle.push(lic.counterSingle);
    dataDC.push(lic.counterDC);

    count.all += lic.counter;
    count.single += lic.counterSingle;
    count.dc += lic.counterDC;
  });

  const datasetAll = [{
    label: i18next.t('all_licenses_type'),
    data: dataAll,
    borderColor: 'rgb(193, 40, 128)',
    backgroundColor: 'rgba(193, 40, 128, 0.5)',
  }];

  const datasetSingle = [{
    label: i18next.t('single_controller_licenses'),
    data: dataSingle,
    borderColor: 'rgb(193, 40, 128)',
    backgroundColor: 'rgba(193, 40, 128, 0.5)',
  }];

  const datasetDC = [{
    label: i18next.t('dual_controller_licenses'),
    data: dataDC,
    borderColor: 'rgb(71, 51, 135)',
    backgroundColor: 'rgba(71, 51, 135, 0.5)',
  }];

  const allDatasets = {
    all: datasetAll,
    SCDC: [...datasetSingle, ...datasetDC],
  };

  return [labels, allDatasets, count];
};

const LicensesChart = (props) => {
  const { licenses, isEra } = props;
  const selectOptions = getSelectOptions();
  const [datasetType, setDatasetType] = useState(selectOptions[0].value);
  const [labels, allDatasets, count] = getDatasets(licenses);
  const [datasets, setDatasets] = useState(allDatasets.all);

  const data = {
    labels,
    datasets,
  };

  useEffect(() => {
    setDatasets(allDatasets[datasetType]);

    if (isEra) {
      setDatasetType(selectOptions[0].value);
    }
  }, [licenses]);

  const onChange = (event) => {
    setDatasetType(event.value);
    setDatasets(allDatasets[event.value]);
  };

  const tooltipFooter = (tooltipItems) => {
    const currentLabel = tooltipItems[0].label;
    const [counterSingle, counterDC] = getCounterByLabel(currentLabel, licenses);

    return (
      `${i18next.t('single_controller_licenses')}: ${counterSingle}
${i18next.t('dual_controller_licenses')}: ${counterDC}`
    );
  };

  return (
    <div>
      <h2>
        {i18next.t('licenses_analytics')}
      </h2>

      {!isEra && (
        <div className="form__row_gap-15">
          <label className="form__label form__label_row form__field_mr-0">
            {i18next.t('license_type')}:
          </label>
          <Select
            name="form-field-name"
            value={datasetType}
            defaultValue={datasetType}
            options={selectOptions}
            clearable={false}
            searchable={false}
            id="license_type"
            className="select_form form__field_mr-0"
            style={{ width: '80px' }}
            onChange={onChange}
          />
        </div>
      )}

      <div className="content__body">
        <div style={{ width: '800px' }}>
          <Line
            plugins={[Legend]}
            options={{
            interaction: {
              intersect: false,
            },
            plugins: {
              legend: {
                position: 'top',
              },
              tooltip: {
                callbacks: {
                  footer: datasetType === 'all' && !isEra ? tooltipFooter : undefined,
                }
              },
            }
          }}
            data={data}
          />
        </div>

        <div
          className="content__body content__body_column"
          style={{ marginLeft: '20px', minWidth: '90px' }}
        >
          <h4>{i18next.t('all_licenses_type_counter')}</h4>

          <div className="form__row_gap-15">
            <label className="form__label form__label_row form__field_mr-0">
              {i18next.t('all')}:
            </label>
            {count.all}
          </div>

          {!isEra
          &&
          <div>
            <div className="form__row_gap-15">
              <label className="form__label form__label_row form__field_mr-0">
                {i18next.t('sc')}:
              </label>
              {count.single}
            </div>

            <div className="form__row_gap-15">
              <label className="form__label form__label_row form__field_mr-0">
                {i18next.t('dc')}:
              </label>
              {count.dc}
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default LicensesChart;
