/* global window */
import React, { PureComponent } from 'react';
import { Tabs } from 'react-tabs';

export default class MemorizeTabs extends PureComponent {
  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(index) {
    window.localStorage.setItem(this.keyLocalStorageKey(), index);
  }

  keyLocalStorageKey() {
    return `tabSelectIndex_${this.props.name}`;
  }

  render() {
    const { name, ...props } = this.props;
    const defaultIndex = window.localStorage.getItem(this.keyLocalStorageKey()) || 0;

    return (
      <Tabs
        {...props}
        defaultIndex={parseInt(defaultIndex, 10)}
        onSelect={this.onSelect}
      >
        {this.props.children}
      </Tabs>
    );
  }
}
