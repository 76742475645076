import React, { PureComponent } from 'react';
import Input from 'components/controls/input';
import Select from 'components/controls/select';
import { supportPeriods } from 'shared/constants';
import { supportPeriodTranslations } from 'constants';
import i18next from 'i18next';

export default class SupportPeriod extends PureComponent {
  constructor(props) {
    super(props);

    const supportPeriodOptions = Object.keys(supportPeriods).map(period => ({
      value: period,
      label: i18next.t(`support_periods.${supportPeriodTranslations[period]}`),
    }));

    this.state = {
      selectedPeriod: props.defaultValue,
      supportPeriodOptions,
      error: false,
    };

    this.emptyValue = props.emptyValue !== undefined ? props.emptyValue : 0;

    this.onChangePeriod = this.onChangePeriod.bind(this);
    this.onChangeDays = this.onChangeDays.bind(this);
  }

  componentDidUpdate() {
    // Если выбран произвольный день, то фокусируемся на поле ввода дней
    if (this.state.selectedPeriod === supportPeriods.SPECIFIED_NUMBER_OF_DAYS) {
      this.inputRef.focus();
    }
  }

  onChangePeriod(event) {
    const { onChange } = this.props;

    if (event) {
      this.setState({
        error: false,
        selectedPeriod: event.value,
      });
    }

    if (typeof onChange === 'function') {
      onChange({
        period: event.value,
        days: this.days,
      });
    }
  }

  onChangeDays(event) {
    const { onChange } = this.props;

    this.error = false;

    if (typeof onChange === 'function') {
      onChange({
        period: this.value,
        days: event.target.value,
      });
    }
  }

  get value() {
    const { selectedPeriod } = this.state;

    if (selectedPeriod === undefined) {
      return this.emptyValue;
    }

    return selectedPeriod;
  }

  get days() {
    const { inputRef } = this;

    // Перед получением количества дней проверяем поле ввода на существование
    return inputRef !== undefined && inputRef !== null && inputRef.value ? inputRef.value : '';
  }

  get error() {
    return this.state.error;
  }

  set days(newValue) {
    this.inputRef.value = newValue;
  }

  set error(newValue) {
    this.setState({
      error: newValue,
    });

    this.selectRef.error = newValue;
    // Выделяем поля ввода, если оно отображается
    if (this.inputRef) {
      this.inputRef.error = newValue !== false;
    }
  }

  render() {
    const { value, id, defaultDays, disabled } = this.props;
    const { selectedPeriod, supportPeriodOptions } = this.state;
    const selectValue = value !== undefined ? value : selectedPeriod;

    const selectProps = {
      ...this.props,
      value: selectValue,
      className: '',
      clearable: false,
      emptyValue: this.emptyValue,
      options: supportPeriodOptions,
      onChange: this.onChangePeriod,
      errorClassName: 'select-component__error_support-period',
      ref: (ref) => { this.selectRef = ref; }
    };

    return (
      <div className="support-period-component">
        <div className="support-period-component__period">
          <Select {...selectProps} />
        </div>
        {
          this.state.selectedPeriod === supportPeriods.SPECIFIED_NUMBER_OF_DAYS ? (
            <div className="support-period-component__day">
              <label htmlFor={`${id}-days`} className="support-period-component__day-label">
                {i18next.t('days')}
              </label>
              <Input
                type="number"
                id={`${id}-days`}
                defaultValue={defaultDays}
                ref={(ref) => { this.inputRef = ref; }}
                onChange={this.onChangeDays}
                className="form__field-text"
                placeholder="194"
                disabled={disabled}
                maxLength="4"
              />
            </div>
          ) : null
        }
      </div>
    );
  }
}
