const FEATURES_ALL = 'FEATURES_ALL';
const licenseModificationConditions = {
  ANY: 'ANY',
};

module.exports = {
  // Проверка валидного email контроллеров и менеджеров raidix
  VALID_STAFF_EMAIL_REX: /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@(raidix.com|raidixstorage.com|digdes.com|docsvision.com)$/i,
  DATE_FORMAT: 'DD MMMM YYYY',
  PRICE_FORMAT: '0,0[.]00',
  PRICE_FORMAT_SHORT: '0,0',
  COMPANY_NAME: 'RAIDIX',
  NUMBER_OF_DRIVES_UNLIMITED: 624,
  MAX_NUMBER_OF_DRIVES: 1200,
  // Hardware key validation
  VALID_HARDWARE_KEY_REX: /^([0-9a-f]{56,})(?::)?([0-9a-f]{1,16})?$/i,
  VALID_ERA_HARDWARE_KEY_REX: /^([0-9a-f]{56,}):([0-9a-f]{1,16})$/i,
  VALID_ONLY_ERA_HARDWARE_KEY_REX: /^([0-9a-f]{1,16})$/i,
  FEATURE_DC_CODE: 'DC',
  FEATURE_ERA_CODE: 'ERA',
  FEATURES_ALL,
  PREMIUM_SUPPORT: 1,
  EXTENDED_RAID: 1,
  OLD_KERNEL_VERSION: 1,
  UNLIMITED_DRIVES: 1,
  NOT_UNLIMITED_DRIVES: 0,
  LICENSE_SUBTYPE_DEFAULT_CODE: 'default',
  ERA_PRODUCT_TYPE: 'era',
  licenseModificationConditions,
  headOfCommercial: 'head_of_commercial',
  headOfCommercialLogin: 'alena.gabriel@raidix.com',

  raidixNagValues: {
    DRIVES: [8, 16],
    SUPPORT_PERIOD: { value: 'ONE_YEAR', days: 0 },
    FEATURES: ['NAS', 'ISCSI'],
  },

  roles: {
    ADMIN: 'admin',
    MANAGER: 'manager',
    ENGINEER: 'engineer',
    ACCOUNTANT: 'accountant',
    PARTNER: 'partner',
  },

  orderStatus: {
    PENDING: 'PENDING',
    DOCUMENTS_SENT: 'DOCUMENTS_SENT',
    GENERATION_READY: 'GENERATION_READY',
    CANCELLED: 'CANCELLED',
  },

  orderStatusTranslations: {
    PENDING: 'pending',
    DOCUMENTS_SENT: 'documents_sent',
    GENERATION_READY: 'generation_ready',
    CANCELLED: 'cancelled',
  },

  productTypes: {
    RAIDIX: 'RAIDIX',
    ERA: 'ERA',
  },

  productTypeTranslations: {
    RAIDIX: 'raidix',
    ERA: 'era',
  },

  orderTypes: {
    COMMERCIAL: 'COMMERCIAL',
    HWKEY_CHANGE: 'HWKEY_CHANGE',
    TRIAL: 'TRIAL',
  },

  orderTypeTranslations: {
    COMMERCIAL: 'commercial',
    HWKEY_CHANGE: 'hardware_key_change',
    TRIAL: 'trial',
  },

  licenseTypes: {
    COMMERCIAL: 'COMMERCIAL',
    TRIAL: 'TRIAL',
  },

  licenseStatuses: {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
  },

  licenseStatusTranslations: {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
  },

  driveTypes: {
    NVME: 'NVME',
    SSD: 'SSD',
  },

  driveTypeTranslations: {
    NVME: 'drive_types.nvme',
    SSD: 'drive_types.ssd',
  },

  currencyTypes: {
    RUB: 'RUB',
    USD: 'USD',
    EUR: 'EUR',
  },

  supportPeriods: {
    WITHOUT_SUPPORT: 'WITHOUT_SUPPORT',
    ONE_YEAR: 'ONE_YEAR',
    TWO_YEARS: 'TWO_YEARS',
    THREE_YEARS: 'THREE_YEARS',
    FOUR_YEARS: 'FOUR_YEARS',
    FIVE_YEARS: 'FIVE_YEARS',
    SPECIFIED_NUMBER_OF_DAYS: 'SPECIFIED_NUMBER_OF_DAYS',
  },

  supportPeriodDates: {
    ONE_YEAR: { years: 1 },
    TWO_YEARS: { years: 2 },
    THREE_YEARS: { years: 3 },
    FOUR_YEARS: { years: 4 },
    FIVE_YEARS: { years: 5 },
    SIX_YEARS: { years: 6 },
  },

  /**
   * License Formats
   * @readonly
   * @enum {licenseFileTypes}
   */
  licenseFileTypes: {
    // Text file with *.lic extension
    LIC: 'LIC',
    // Archive with text files
    RPK: 'RPK',
  },

  /**
   * Kernel Types
   * @readonly
   * @enum {kernelTypes}
   */
  kernelTypes: {
    OLD: 'OLD',
    NEW: 'NEW',
  },

  /**
   * @readonly
   * @enum {licenseFileTypes}
   */
  licenseDataTypes: {
    TEXT: 'TEXT',
    FILEPATH: 'FILE_PATH',
  },

  /**
   * TODO: Написать тесты сверяющие порядок и количество, статусов и ролей с матрицей
   *
   * В матрице описаны доступные статусы, для уже выбранных
   * статусов с учётом прав доступа и типа заказа.
   *
   * A - admin
   * M - manager
   * E - engineer
   * B - accountant
   * P - partner
   * H - head of commercial (order status local role)
   *
   * P - pending
   * D - documents_sent
   * G - generation_ready
   * C - cancelled
   * * - All status
   * - - not status
   */
  orderStatusMatrix: {
    COMMERCIAL: [
      /*         A    M     E     B      P     H   */
      /* P */ [ '*', 'PC', 'PC', 'PDC', 'PC', 'PGC' ],
      /* D */ [ '*', 'D',  'D',  'DGC', 'D',  'DGC' ],
      /* G */ [ '*', 'G',  'G',  'G',   'G',  'G'   ],
      /* C */ [ '*', 'C',  'C',  'C',   'C',  'C'   ],
    ],
    TRIAL: [
      /*         A    M    E    B    P    H */
      /* P */ [ '-', '-', '-', '-', '-', '-' ],
      /* D */ [ '-', '-', '-', '-', '-', '-' ],
      /* G */ [ 'G', 'G', 'G', 'G', 'G', 'G' ],
      /* C */ [ 'G', 'C', 'C', 'C', 'C', 'C' ],
    ],
    HWKEY_CHANGE: [
      /*         A      M      E      B    P     H  */
      /* P */ [ 'PGC', 'PGC', 'PGC', 'P', 'PC', 'P' ],
      /* D */ [ '-',   '-',   '-',   '-', '-',  '-' ],
      /* G */ [ 'PGC', 'G',   'G',   'G', 'G',  'G' ],
      /* C */ [ 'PGC', 'C',   'C',   'C', 'C',  'C' ],
    ],
  },
  licenseSubtypes: {
    default: {
      drives: {
        edit: true,
        default: 0,
      },
      supportPeriod: {
        edit: true,
        premium: true,
        default: {
          value: '',
          days: 0,
        },
      },
      features: {
        edit: true,
        values: FEATURES_ALL,
      },
      raidLevels: {
        values: ['0', '1', '5', '50', '6', '60', '10', '70', '7.3', 'nm'],
      }
    },
    raidix_lite: {
      drives: {
        edit: true,
        default: 8,
        values: [8, 16],
      },
      supportPeriod: {
        edit: true,
        premium: false,
        default: {
          value: '',
          days: 0,
        },
      },
      features: {
        edit: false,
        values: ['NAS', 'ISCSI'],
      },
      raidLevels: {
        values: ['0', '5', '6', '10'],
      }
    },
    era: {
      drives: {
        edit: true,
        default: 0,
      },
      supportPeriod: {
        edit: true,
        premium: true,
        default: {
          value: '',
          days: 0,
        },
      },
      raidLevels: {},
      modificationConditions: {
        linuxDistribution: licenseModificationConditions.ANY,
        linuxKernel: licenseModificationConditions.ANY,
        productVersion: licenseModificationConditions.ANY,
      }
    }
  },
  hardwareKeyLength: {
    graid: 56,
    era: 16,
    complex: 73,
  }
};
