import { connect } from 'react-redux';
import Orders from 'components/pages/orders';
import dataFetcherEnhance from 'components/data-fetcher-enhance';
import { actions as ordersDataActions } from 'ducks/data/orders';
import { actions as ordersInfoDataActions } from 'ducks/data/orders-info';
import { actions as licensesDataActions } from 'ducks/data/licenses';
import { actions as eraLicensesDataActions } from 'ducks/data/era-licenses';
import { actions as usersDataActions } from 'ducks/data/users';
import { actions as countriesDataActions } from 'ducks/data/countries';

const mapDispatchToProps = Object.assign(
  {},
  ordersDataActions,
  licensesDataActions,
  eraLicensesDataActions,
  usersDataActions,
  ordersInfoDataActions,
  countriesDataActions,
);

function mapStateToProps(state) {
  return {
    authDataIm: state.data.authDataIm,
    ordersDataIm: state.data.ordersDataIm,
    ordersInfoDataIm: state.data.ordersInfoDataIm,
    licensesDataIm: state.data.licensesDataIm,
    eraLicensesDataIm: state.data.eraLicensesDataIm,
    usersDataIm: state.data.usersDataIm,
    countriesDataIm: state.data.countriesDataIm,
    fetchActionNames: [
      'usersDataGetSignal',
      'ordersInfoDataGetSignal',
      'countriesDataGetSignal',
    ],
  };
}

const OrdersWithFetch = dataFetcherEnhance(Orders);

export default connect(mapStateToProps, mapDispatchToProps)(OrdersWithFetch);
