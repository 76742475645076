import { Map, fromJS } from 'immutable';
import { applicationName } from 'config';
import { fetchSignal } from 'ducks/fetch';

const MODULE_NAME = 'analytics-data';
const URL = '/api/analytics';

const ANALYSE_DONE = `${applicationName}/${MODULE_NAME}/ANALYSE_DONE`;
const RESET = `${applicationName}/${MODULE_NAME}/RESET`;

const initialState = Map({ data: false });

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ANALYSE_DONE:
      return state.set('data', fromJS(action.payload));

    default:
      return state;
  }
}

const analyticsDataResetDelta = () => ({ type: RESET });

const analyticsDataAddSignal = ({ data }) => dispatch => Promise.coroutine(function* addAnalytics() {
  const answer = yield dispatch(fetchSignal(URL, { method: 'PATCH', body: data }));

  if (answer.isSuccess) {
    dispatch({ type: ANALYSE_DONE, payload: answer.data });
  }

  return answer;
})();

export const actions = {
  analyticsDataResetDelta,
  analyticsDataAddSignal,
};
