import React, { PureComponent, Fragment } from 'react';
import i18next from 'i18next';
import Select from 'components/controls/select';
import classnames from 'classnames';
import { orderStatusColors } from 'constants';
import StatusOption from './status-option';
import StatusValue from './status-value';

export default class OrderStatus extends PureComponent {
  get value() {
    return this.selectRef.value;
  }

  get error() {
    return this.selectRef.error;
  }

  set error(newValue) {
    this.selectRef.error = newValue;
  }

  set value(value) {
    this.selectRef.value = value;
  }

  getColorOptions() {
    return this.props.options.map(option => ({
      ...option,
      className: `Select-value_color-${orderStatusColors[option.value]}`,
    }));
  }

  render() {
    const { disabled, multi } = this.props;

    const placeholderClassName = classnames(
      'order-status__value-icon',
      { 'order-status__value-icon_disabled': disabled },
    );

    const placeholder = !this.props.placeholder ? (
      <Fragment>
        <span className={placeholderClassName} />
        <span className="order-status__value">{`${i18next.t('select')}...`}</span>
      </Fragment>
    ) : this.props.placeholder;

    const options = multi ? this.getColorOptions() : this.props.options;
    const valueComponent = !multi ? StatusValue : this.props.valueComponent;

    const props = {
      ...this.props,
      placeholder,
      options,
      valueComponent,
      optionComponent: StatusOption,
      ref: (ref) => { this.selectRef = ref; },
    };

    return (
      <Select {...props} />
    );
  }
}
