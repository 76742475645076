import { connect } from 'react-redux';
import EditUser from 'components/pages/edit-user';
import dataFetcherEnhance from 'components/data-fetcher-enhance';
import { actions as usersDataActions } from 'ducks/data/users';
import { actions as licenseSubtypesDataActions } from 'ducks/data/license-subtype';
import { actions as userLicenseSubtypesDataActions } from 'ducks/data/user-license-subtype';

const mapDispatchToProps = Object.assign(
  {},
  usersDataActions,
  licenseSubtypesDataActions,
  userLicenseSubtypesDataActions,
);

function mapStateToProps(state) {
  return {
    authDataIm: state.data.authDataIm,
    usersDataIm: state.data.usersDataIm,
    licenseSubtypesDataIm: state.data.licenseSubtypesDataIm,
    userLicenseSubtypesDataIm: state.data.userLicenseSubtypesDataIm,
    fetchActionNames: [
      'usersDataGetSignal',
      'licenseSubtypesDataGetSignal',
      'userLicenseSubtypesDataGetSignal',
    ],
  };
}

const EditUserWithFetch = dataFetcherEnhance(EditUser);

export default connect(mapStateToProps, mapDispatchToProps)(EditUserWithFetch);
