import React, { PureComponent } from 'react';
import i18next from 'i18next';
import Input from 'components/controls/input';

export default class AuthForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      timeRemaining: props.timeout,
      showTimeout: props.showTimeout,
    };

    this.timer = null;
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  get login() {
    return this.loginRef;
  }

  get password() {
    return this.passwordRef;
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.setState(({ timeRemaining }) => ({ timeRemaining: timeRemaining - 1000 }));
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.timer);
  }

  renderAuthTimeoutError() {
    const timeRemaining = new Date(this.state.timeRemaining);
    const minutes = timeRemaining.getMinutes();
    const seconds = timeRemaining.getSeconds();

    if (this.state.showTimeout && this.state.timeRemaining > 0) {
      return (
        <div className="form__field form__field_error">
          {i18next.t('login_timeout_error')}
          <br />
          {i18next.t('login_timeout_error_timer', { minutes, seconds })}
        </div>
      );
    }

    this.stopTimer();
    return null;
  }

  render() {
    return (
      <div className="auth">
        <form className="form form_auth">
          <h1 className="auth__title">
            {i18next.t('license_service')}
          </h1>
          <div className="form__row">
            <div className="form__field">
              <label htmlFor="auth__block_login">
                {i18next.t('login')}
              </label>
              <Input
                id="auth__block_login"
                className="form__field-text form__field-text_padding"
                ref={(ref) => { this.loginRef = ref; }}
                type="text"
                name="login"
              />
            </div>
            <div className="form__field">
              <label htmlFor="auth__block_password">
                {i18next.t('password')}
              </label>
              <Input
                id="auth__block_password"
                className="form__field-text form__field-text_padding"
                ref={(ref) => { this.passwordRef = ref; }}
                type="password"
                name="password"
              />
            </div>
            {this.renderAuthTimeoutError()}
          </div>
          <div className="form__footer">
            <button className="button button_form button_submit" onClick={this.props.onSubmit}>
              {i18next.t('log_in')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}
