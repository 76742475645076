/* eslint-disable react/no-danger */
import React, { Fragment } from 'react';
import i18next from 'i18next';
import ModalHeader from 'components/modal/modal-header';
import ModalButtons from 'components/modal/modal-buttons';

const messageText = (message, props) => ({
  __html: i18next.t(message, { ...props }),
});

export default function ConfirmModal({ modalComponentIm, modalClose }) {
  const { title, message, resolve, props } = modalComponentIm.getIn(['options']);

  return (
    <Fragment>
      <ModalHeader text={i18next.t(title)} />
      <div dangerouslySetInnerHTML={messageText(message, props)} />
      <ModalButtons
        resolve={resolve}
        modalClose={modalClose}
      />
    </Fragment>
  );
}
