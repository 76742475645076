import { Map, fromJS } from 'immutable';
import { applicationName } from 'config';
import { fetchSignal } from 'ducks/fetch';

/*
* Constants
* */

export const MODULE_NAME = 'current-user-license-subtypes-data';
export const URL = '/api/user-license-subtypes';

// Action names
const FETCH_DONE = `${applicationName}/${MODULE_NAME}/FETCH_DONE`;
const RESET = `${applicationName}/${MODULE_NAME}/RESET`;

/*
* Reducer
* */

const initialState = Map({ data: false });

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DONE:
      return state.set('data', fromJS(action.payload));

    case RESET:
      return initialState;

    default:
      return state;
  }
}

/*
* Actions
* */

const currentUserLicenseSubtypesDataResetDelta = () => ({ type: RESET });

const currentUserLicenseSubtypesDataGetSignal = ({ currentUser }) => dispatch => Promise.coroutine(
  function* getUser() {
    const answer = yield dispatch(fetchSignal(`${URL}/${currentUser.id}`));

    if (answer.isSuccess) {
      dispatch({ type: FETCH_DONE, payload: answer.data });
    }

    return answer;
  }
)();

export const actions = {
  currentUserLicenseSubtypesDataResetDelta,
  currentUserLicenseSubtypesDataGetSignal,
};
