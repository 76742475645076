import { Map, fromJS } from 'immutable';
import { applicationName } from 'config';
import { fetchSignal } from 'ducks/fetch';

/*
* Constants
* */

export const MODULE_NAME = 'orders-data';
export const URL = '/api/orders';
export const URL_CVS = `${URL}/csv`;
const URL_TABLE = `${URL}/table`;

// Action names
const FETCH_DONE = `${applicationName}/${MODULE_NAME}/FETCH_DONE`;
const UPDATE_DONE = `${applicationName}/${MODULE_NAME}/UPDATE_DONE`;
const ADD_DONE = `${applicationName}/${MODULE_NAME}/ADD_DONE`;
const RESET = `${applicationName}/${MODULE_NAME}/RESET`;
const UPDATE_ERA_TOTAL = `${applicationName}/${MODULE_NAME}/UPDATE_ERA_TOTAL`;
const UPDATE_RAIDIX_TOTAL = `${applicationName}/${MODULE_NAME}/UPDATE_RAIDIX_TOTAL`;
const ERA_FETCH_DONE = `${applicationName}/${MODULE_NAME}/ERA_FETCH_DONE`;
const RAIDIX_FETCH_DONE = `${applicationName}/${MODULE_NAME}/RAIDIX_FETCH_DONE`;

/*
* Reducer
* */

const initialState = Map({ data: false, dataEra: false, dataRaidix: false, dataTotalEra: null, dataTotalRaidix: null });

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ERA_TOTAL:
      return state.set('dataTotalEra', action.payload);

    case UPDATE_RAIDIX_TOTAL:
      return state.set('dataTotalRaidix', action.payload);

    case ERA_FETCH_DONE:
      return state.set('dataEra', fromJS(action.payload));

    case RAIDIX_FETCH_DONE:
      return state.set('dataRaidix', fromJS(action.payload));

    case FETCH_DONE:
      return state.set('data', fromJS(action.payload));

    case ADD_DONE:
      return state.update('data', data => data.push(fromJS(action.payload)));

    case UPDATE_DONE: {
      return state.update('data', data => data.map((dataItem) => {
        if (dataItem.get('id') === action.payload.id) {
          return dataItem.merge(action.payload);
        }

        return dataItem;
      }));
    }

    case RESET:
      return initialState;

    default:
      return state;
  }
}

/*
* Actions
* */

const ordersDataResetDelta = () => ({ type: RESET });

const ordersDataGetSignal = () => dispatch => Promise.coroutine(function* getOrder() {
  const answer = yield dispatch(fetchSignal(URL));

  if (answer.isSuccess) {
    dispatch({ type: FETCH_DONE, payload: answer.data });
  }

  return answer;
})();

const ordersCsvDataGetSignal = ({ data }) => dispatch => Promise.coroutine(function* getOrder() {
  return yield dispatch(fetchSignal(URL_CVS, { method: 'POST', body: data }));
})();

const ordersDataUpdateSignal = ({ id, data }) =>
  dispatch => Promise.coroutine(function* updatePartners() {
    const answer = yield dispatch(fetchSignal(`${URL}/${id}`, { method: 'PATCH', body: data }));

    if (answer.isSuccess) {
      dispatch({ type: UPDATE_DONE, payload: answer.data });
    }

    return answer;
  })();

  // TODO: Нужно ли обновлять данные для order?
const ordersDataAddSignal = ({ data }) => dispatch => Promise.coroutine(function* addPartner() {
  const answer = yield dispatch(fetchSignal(URL, { method: 'POST', body: data }));
  return answer;
})();

const ordersEraDataGetSignal = ({ page, pageSize, sort, filters } = {}) => dispatch =>
  Promise.coroutine(function* getLicense() {
    const data = {
      isEra: 1,
      page: page || 0,
      pageSize: pageSize || 10,
    };

    if (sort) {
      data.sort = sort;
    }

    if (filters) {
      data.filter = filters;
    }

    const answer = yield dispatch(fetchSignal(URL_TABLE, { method: 'PATCH', body: data }));

    if (answer.isSuccess) {
      dispatch({ type: ERA_FETCH_DONE, payload: answer.data.orders });
      dispatch({ type: UPDATE_ERA_TOTAL, payload: answer.data.totalCount });
    }

    return answer;
  })();

const ordersRaidixDataGetSignal = ({ page, pageSize, sort, filters } = {}) => dispatch =>
  Promise.coroutine(function* getLicense() {
    const data = {
      isEra: 0,
      page: page || 0,
      pageSize: pageSize || 10,
    };

    if (sort) {
      data.sort = sort;
    }

    if (filters) {
      data.filter = filters;
    }

    const answer = yield dispatch(fetchSignal(URL_TABLE, { method: 'PATCH', body: data }));

    if (answer.isSuccess) {
      dispatch({ type: RAIDIX_FETCH_DONE, payload: answer.data.orders });
      dispatch({ type: UPDATE_RAIDIX_TOTAL, payload: answer.data.totalCount });
    }

    return answer;
  })();

const orderByIdGetSignal = ({ id } = {}) => dispatch =>
  Promise.coroutine(function* getOrderByIdGetSignal() {
    const URL_WITH_ID = `${URL}?orderId=${id}`;
    const answer = yield dispatch(fetchSignal(URL_WITH_ID));

    if (answer.isSuccess) {
      dispatch({ type: FETCH_DONE, payload: answer.data });
    }

    return answer;
  })();

export const actions = {
  ordersDataResetDelta,
  ordersDataGetSignal,
  ordersCsvDataGetSignal,
  ordersDataUpdateSignal,
  ordersDataAddSignal,
  ordersEraDataGetSignal,
  ordersRaidixDataGetSignal,
  orderByIdGetSignal,
};
