import React from 'react';
import { ServerError } from 'components/pages/error';

const ServerErrorPage = () => (
  <div className="content">
    <div className="content__body">
      <ServerError />
    </div>
  </div>
);

export default ServerErrorPage;
