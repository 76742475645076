import React, { PureComponent } from 'react';
import i18next from 'i18next';
import classnames from 'classnames';
import { roles } from 'constants';
import { VALID_STAFF_EMAIL_REX } from 'shared/constants';
import { isEmailValidation } from 'shared/functions';
import Input from 'components/controls/input';

export default class PersonalisationForm extends PureComponent {
  constructor(props) {
    super(props);

    const { userIm } = props;
    const role = userIm.get('role');

    this.state = {
      role,
      isPasswordChanged: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    const { userIm } = this.props;
    let options = { };

    if (this.passwordRef.value.length) {
      options = {
        ...options,
        currentPassword: this.currentPasswordRef.value,
        password: this.passwordRef.value,
      };
    }

    if (this.emailRef.value.length && this.emailRef.value !== this.getInitValue().email) {
      options = {
        ...options,
        currentPassword: this.currentPasswordRef.value,
        email: this.emailRef.value,
      };
    }

    const validateOptions = {
      ...options,
      confirmPassword: this.confirmPasswordRef.value,
    };

    if (Object.keys(options).length && this.validate(validateOptions)) {
      const fields = { data: options };
      fields.id = userIm.get('id');

      this.props.formComponentSubmitWrapperSignal({
        submitSignal: () => this.props.submitSignal(fields),
        successSignal: () => this.props.formComponentAddSuccessSignal(),
        doneText: i18next.t('user_changes_saved'),
      });

      this.currentPasswordRef.value = '';
      this.passwordRef.value = '';
      this.confirmPasswordRef.value = '';
    }
  }

  getInitValue() {
    const { userIm } = this.props;
    const value = { };

    if (userIm) {
      value.email = userIm.get('email');
    }

    return value;
  }

  handlePasswordChange() {
    this.setState({ isPasswordChanged: this.passwordRef.value.length > 0 });
  }

  validate(options) {
    const {
      currentPassword,
      password,
      confirmPassword,
      email,
    } = options;
    let isValid = true;

    if (password && password.length) {
      if (!currentPassword.length) {
        this.currentPasswordRef.error = i18next.t('v.required');
        isValid = false;
      }

      if (!password.length) {
        this.passwordRef.error = i18next.t('v.required');
        isValid = false;
      } else if (password.length < 6) {
        this.passwordRef.error = i18next.t('v.must_be_longer_than', { count: 5 });
        isValid = false;
      }

      if (!confirmPassword.length) {
        this.confirmPasswordRef.error = i18next.t('v.required');
        isValid = false;
      } else if (password !== confirmPassword) {
        this.confirmPasswordRef.error = i18next.t('v.not_match_password');
        isValid = false;
      }
    }

    if (email && email.length) {
      if (!currentPassword.length) {
        this.currentPasswordRef.error = i18next.t('v.required');
        isValid = false;
      }

      if ((this.state.role === roles.PARTNER && !isEmailValidation(email)) ||
      (this.state.role !== roles.PARTNER && !VALID_STAFF_EMAIL_REX.test(email))) {
        this.emailRef.error = i18next.t('v.invalid_email');
        isValid = false;
      }
    }
    return isValid;
  }

  render() {
    const { isEdit } = this.props;
    const { email } = this.getInitValue();
    let isDisabled = false;

    if (this.props.formComponentIm !== undefined) {
      isDisabled = this.props.formComponentIm.get('isDisabled');
    }

    return (
      <form className="form">
        <div className="form__row form__row_2">
          <div className="form__field">
            <label
              htmlFor="add-user-current-password"
              className="form__label form__label_required"
            >
              {i18next.t('current_pussword')}
            </label>
            <Input
              type="password"
              id="add-user-current-password"
              ref={(ref) => { this.currentPasswordRef = ref; }}
              className="form__field-text form__field-text_padding"
              placeholder="Secret password"
              autoComplete="off"
            />
          </div>

          <div className="form__field">
            <label htmlFor="add-user-email" className="form__label">{i18next.t('email')}</label>
            <Input
              type="text"
              id="add-user-email"
              defaultValue={email}
              ref={(ref) => { this.emailRef = ref; }}
              className="form__field-text form__field-text_padding"
              placeholder="morris.j@company.com"
            />
          </div>

          <div className="form__field">
            <label
              htmlFor="add-user-password"
              className="form__label"
            >
              {i18next.t('password')}
            </label>
            <Input
              type="password"
              id="add-user-password"
              ref={(ref) => { this.passwordRef = ref; }}
              onChange={this.handlePasswordChange}
              className="form__field-text form__field-text_padding"
              placeholder="Secret password"
              autoComplete="off"
            />
          </div>

          <div className="form__field" />

          <div className="form__field">
            <label
              htmlFor="add-user-repeat-password"
              className={classnames('form__label', { form__label_required: this.state.isPasswordChanged })}
            >
              {i18next.t('repeat_password')}
            </label>
            <Input
              type="password"
              id="add-user-repeat-password"
              ref={(ref) => { this.confirmPasswordRef = ref; }}
              className="form__field-text form__field-text_padding"
              placeholder="Secret password"
              autoComplete="off"
            />
          </div>
        </div>

        <div className="form__row form__row_2 form__row_footer">
          <div className="form__field form__field_width_auto">
            <button
              className="button button_form button_submit"
              onClick={this.onSubmit}
              disabled={isDisabled}
            >
              {isEdit !== true ? i18next.t('create') : i18next.t('save')}
            </button>
          </div>
        </div>
      </form>
    );
  }
}
