import React, { PureComponent } from 'react';
import Input from 'components/controls/input';
import ReactTooltip from 'react-tooltip';
import LockIcon from 'img/lock.svg';
import PasswordButton from './password-button';

export default class InputPassword extends PureComponent {
  constructor(props) {
    super(props);

    this.onGenaratePassword = this.onGenaratePassword.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
  }

  onGenaratePassword({ password }) {
    this.props.setPasswordType('generated');
    this.value = password;
  }

  onChangeValue(event) {
    const { value } = event.target;

    if (value === '') {
      this.props.setPasswordType('manual');
    }
    this.value = value || '';
  }

  get value() {
    return this.inputRef.value;
  }

  set value(newValue) {
    this.inputRef.value = newValue;
  }

  set error(newValue) {
    this.inputRef.error = newValue;
  }

  render() {
    const {
      placeholder = 'password',
    } = this.props;

    const props = {
      ...this.props,
      placeholder,
      className: 'form__field-text form__field-text_currency',
      errorClassName: 'input-component__error_price',
      ref: (ref) => { this.inputRef = ref; },
    };

    return (
      <div className="input-price-component form__field-text_padding">
        <Input {...props} onChange={this.onChangeValue} />
        <div className="input-price-component__currencies">
          <PasswordButton
            key="generate"
            onClick={this.onGenaratePassword}
          >
            <LockIcon
              width="14"
              height="14"
            />
          </PasswordButton>
        </div>
        <ReactTooltip
          id="generate_password"
          effect="solid"
          delayHide={200}
          multiline
        />
      </div>
    );
  }
}
