import React, { PureComponent } from 'react';
import Preloader from 'components/preloader';
import _isFunction from 'lodash/isFunction';
import { ServerErrorPage } from 'components/data-fetcher-enhance';

const isErrorStatus = status => (status !== 200 && status !== 401 && status !== 403);

export default class FetchData extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isError: false,
    };
  }

  componentWillMount() {
    const { fetchActionNames = [], fetchAttributesByActionNames = {} } = this.props;
    const promises = [];

    fetchActionNames.forEach((fetchActionName) => {
      const attributes = fetchAttributesByActionNames[fetchActionName];
      const promis = this.props[fetchActionName](attributes);

      promises.push(promis);
    });

    Promise.all(promises).then((data) => {
      const isError = data.some(({ status }) => isErrorStatus(status));
      const state = isError
        ? { isError: true }
        : { isLoading: false };

      this.setState(state);
    });
  }

  render() {
    const { children, updateProps } = this.props;
    const { isLoading, isError } = this.state;
    const props = _isFunction(updateProps) ? updateProps(this.props) : {};

    if (isError) {
      return <ServerErrorPage />;
    }

    return isLoading
      ? <Preloader />
      : React.cloneElement(children, { ...props });
  }
}
