import React, { PureComponent } from 'react';

// id property is required
export default class Checkbox extends PureComponent {
  get checked() {
    return this.inputRef.checked;
  }

  set checked(value) {
    this.inputRef.checked = value;
  }

  render() {
    const props = {
      ...this.props,
      type: 'checkbox',
      className: 'checkbox_input',
      ref: (ref) => { this.inputRef = ref; },
    };

    return (
      <div className="checkbox">
        <input {...props} />
        <label className="checkbox_label" htmlFor={this.props.id} > {this.props.label} </label>
      </div>
    );
  }
}
