import React from 'react';
import i18next from 'i18next';
import { orderStatusColors } from 'constants';
import { orderStatusTranslations } from 'shared/constants';
import Chip from 'components/chip';
import TableCell from './cell';

export default function OrderStatusCell({ value, className }) {
  const translation = i18next.t(orderStatusTranslations[value]);
  const typeClass = `chip_${orderStatusColors[value]}`;

  return (
    <TableCell className={className}>
      <Chip className={typeClass} title={translation}>{translation}</Chip>
    </TableCell>
  );
}
