import React, { PureComponent } from 'react';

export default class ActionButton extends PureComponent {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    event.preventDefault();

    this.props.onClick(this.props.item);
  }

  render() {
    const { label } = this.props;

    return (
      <a
        href="#action"
        className="popup-menu__item"
        onClick={this.onClick}
      >
        {label}
      </a>
    );
  }
}
