import React, { PureComponent } from 'react';
import i18next from 'i18next';

export default class ModalButtons extends PureComponent {
  constructor(props) {
    super(props);

    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onCancel() {
    this.props.resolve(false);
    this.props.modalClose();
  }

  onSubmit() {
    this.props.resolve(true);
    this.props.modalClose();
  }

  render() {
    return (
      <div className="modal__footer-buttons">
        <div className="controls-group controls-group_form">
          <div className="controls-group__control">
            <input
              type="button"
              className="button"
              value={i18next.t('cancel')}
              onClick={this.onCancel}
              autoFocus
            />
          </div>
          <div className="controls-group__control">
            <input
              type="button"
              className="button button_submit"
              value={i18next.t('yes')}
              onClick={this.onSubmit}
            />
          </div>
        </div>
      </div>
    );
  }
}
