import React, { PureComponent, Fragment } from 'react';
import i18next from 'i18next';
import _map from 'lodash/map';
import {
  orderTypes,
  driveTypes,
  EXTENDED_RAID,
  OLD_KERNEL_VERSION,
  driveTypeTranslations,
} from 'shared/constants';
import Select from 'components/controls/select';
import Input from 'components/controls/input';
import Checkbox from 'components/controls/checkbox';
import RadioButtonList from 'components/controls/radio-button-list';

export default class EraFunctionality extends PureComponent {
  get value() {
    return {
      driveType: this.driveTypeRef.value,
      linuxDistribution: this.linuxDistributionRef.value,
      linuxKernel: this.linuxKernelRef.value,
      productVersion: this.productVersionRef.value,
      oldKernelVersion: this.oldKernelVersionRef.checked ? this.oldKernelVersionRef.props.value : 0,
      extendedRaid: this.extendedRaidRef.checked ? this.extendedRaidRef.props.value : 0,
    };
  }

  getDriveTypeOptions() {
    return _map(driveTypes, (value, name) => ({
      label: i18next.t(driveTypeTranslations[name]),
      value,
    }));
  }

  getProductVersionOptions() {
    const {
      currentProductVersion,
      productVersionsDataIm,
    } = this.props;

    return productVersionsDataIm.get('data')
      .filter(productVersion =>
        productVersion.get('active') ||
        (currentProductVersion && currentProductVersion === productVersion.get('id')))
      .map(productVersion => ({
        label: productVersion.get('name'),
        value: productVersion.get('id'),
      }))
      .toArray();
  }

  validate() {
    if (!this.driveTypeRef.value) {
      this.driveTypeRef.error = i18next.t('v.required');

      return false;
    }

    return true;
  }

  render() {
    const {
      id,
      orderType,
      disabled,
      oldKernelVersion,
      driveType,
      extendedRaid,
      linuxDistribution,
      linuxKernel,
      productVersion,
    } = this.props;

    const disabledTrial = disabled || orderType === orderTypes.TRIAL;

    return (
      <Fragment>
        <div className="form__row form__row_2">
          <div className="form-line" />
        </div>

        <div className="form__row form__row_2 era-functionality">
          <div className="era-functionality__column">
            <div className="era-functionality__title">{i18next.t('features')}</div>
            <div className="form__field form__field_auto form__field-radio-button">
              <div className="form__label form__label_required">{i18next.t('drive_type')}</div>
              <RadioButtonList
                name={`license-form-${id}-drive-type`}
                options={this.getDriveTypeOptions()}
                defaultValue={driveType}
                emptyValue={0}
                disabled={disabledTrial}
                ref={(ref) => { this.driveTypeRef = ref; }}
              />
            </div>

            <div className="form__field form__field_auto form__field-checkbox">
              <Checkbox
                id={`license-form-${id}-old-kernel-version`}
                ref={(ref) => { this.oldKernelVersionRef = ref; }}
                name="oldKernelVersion"
                value={OLD_KERNEL_VERSION}
                label={i18next.t('older_kernel_version')}
                disabled={disabled}
                defaultChecked={oldKernelVersion}
              />
            </div>

            <div className="form__field form__field_auto form__field-checkbox">
              <Checkbox
                id={`license-form-${id}-extended-raid`}
                ref={(ref) => { this.extendedRaidRef = ref; }}
                name="extendedRaid"
                value={EXTENDED_RAID}
                label={i18next.t('additional_raid')}
                disabled={disabledTrial}
                defaultChecked={extendedRaid}
              />
            </div>
          </div>
          <div className="era-functionality__column">
            <div className="fieldset">
              <div className="fieldset__legend">{i18next.t('system_info')}</div>
              <div className="form__field form__field_auto">
                <label htmlFor={`license-form-${id}-linux-distribution`} className="form__label">
                  {i18next.t('linux_dist')}
                </label>
                <Input
                  type="text"
                  id={`license-form-${id}-linux-distribution`}
                  ref={(ref) => { this.linuxDistributionRef = ref; }}
                  className="form__field-text form__field-text_padding"
                  placeholder="Ubuntu 20.10"
                  defaultValue={linuxDistribution}
                />
              </div>
              <div className="form__field form__field_auto">
                <label htmlFor={`license-form-${id}-kernel-version`} className="form__label">
                  {i18next.t('kernel_version')}
                </label>
                <Input
                  type="text"
                  id={`license-form-${id}-kernel-version`}
                  ref={(ref) => { this.linuxKernelRef = ref; }}
                  className="form__field-text form__field-text_padding"
                  placeholder="5.4.0-1"
                  defaultValue={linuxKernel}
                />
              </div>
              <div className="form__field form__field_auto">
                <label htmlFor={`license-form-${id}-product-version`} className="form__label">
                  {i18next.t('era_version')}
                </label>
                <Select
                  id={`license-form-${id}-product-version`}
                  className="select_form"
                  clearable={false}
                  options={this.getProductVersionOptions()}
                  defaultValue={productVersion}
                  emptyValue={0}
                  ref={(ref) => { this.productVersionRef = ref; }}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
