import React, { PureComponent } from 'react';
import i18next from 'i18next';
import FunnelIcon from 'img/funnel.svg';

export default class FilterButton extends PureComponent {
  render() {
    let filterPropsCount = 0;

    if (this.props.tableComponentIm && !this.props.tableComponentIm.get('quickFilter')) {
      const filters = this.props.tableComponentIm.getIn(['filters']);

      filterPropsCount = filters && filters.size ? filters.size : filterPropsCount;
    }

    return (
      <button className="button button_icon">
        <span className="button__icon">
          <FunnelIcon
            width="16"
            height="16"
          />
        </span>
        <span className="button__text">
          {i18next.t('filter')}
        </span>
        {
          filterPropsCount ? (
            <span className="button__count">
              {filterPropsCount}
            </span>
          ) : null
        }
      </button>
    );
  }
}
