import { connect } from 'react-redux';
import FilterButton from 'components/table-filter/filter-button';

function mapStateToProps(state, ownProps) {
  return {
    tableComponentIm: state.components.tableComponentIm.get(ownProps.id),
  };
}

export default connect(mapStateToProps)(FilterButton);
