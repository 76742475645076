import React, { Fragment } from 'react';
import i18next from 'i18next';
import { orderTypes } from 'shared/constants';

export const LicenseAddButton = ({ orderType, onAddLicense }) => {
  const isCommercial = orderType === orderTypes.COMMERCIAL;
  const isTrial = orderType === orderTypes.TRIAL;

  return (
    <Fragment>
      {
        (isCommercial || isTrial) ? (
          <div className="controls-group__control">
            <input
              type="button"
              className="button"
              value={i18next.t('add_license')}
              onClick={onAddLicense}
            />
          </div>
        ) : null
      }
    </Fragment>
  );
};
