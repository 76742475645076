/*
* HOC для предзагрузки данных для компонента
* */
import React, { PureComponent } from 'react';
import Preloader from 'components/preloader';
import PropTypes from 'prop-types';
import fetchedDataManager from './fetched-data-manager';
import ServerErrorPage from './server-error-page';

const isErrorStatus = status => (status !== 200 && status !== 401 && status !== 403);

export default (ComposedComponent, CustomPreloader) =>
  class DataFetcherWrapper extends PureComponent {
    static propTypes = {
      fetchActionNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    };

    constructor(props) {
      super(props);

      this.state = {
        isLoading: true,
        isError: false,
      };

      this.Preloader = CustomPreloader || Preloader;
    }

    componentWillMount() {
      // TODO: переехал сюда из pageComponentEnhance как временное решение
      // Так как в react 16 порядок событий componentWillUnmount и componentWillMount изменился,
      // ранее сначало очищались старые данные потом добовлялись новые. В react 16 сначало
      // добовлялись новый данные в общий список затем удалялись вместе со старыми, что приводило
      // к ошибке. Переезд метода временное решение необходимо придумать лучшее решения
      // для этой проблемы.
      fetchedDataManager.clearFetchedData();

      const promises = [];
      const { authDataIm, fetchActionNames, match } = this.props;
      const fetchedData = fetchedDataManager.getFetchedData();
      let currentUserId;
      let currentUserRole;

      if (authDataIm) {
        currentUserId = authDataIm.getIn(['data', 'id']);
        currentUserRole = authDataIm.getIn(['data', 'role']);
      }

      fetchActionNames.forEach((fetchActionName) => {
        if (!fetchedData[fetchActionName]) {
          fetchedData[fetchActionName] = this.props[fetchActionName]({
            params: match.params,
            currentUser: {
              id: currentUserId,
              role: currentUserRole
            },
          });
        }

        promises.push(fetchedData[fetchActionName]);
      });

      Promise.all(promises).then((data) => {
        const isError = data.some(({ status }) => isErrorStatus(status));

        if (isError) {
          this.setState({ isError: true });

          return;
        }

        this.setState({ isLoading: false });
      });
    }

    render() {
      if (this.state.isError) {
        return <ServerErrorPage />;
      }

      return this.state.isLoading ?
        <this.Preloader /> :
        <ComposedComponent {...this.props} />;
    }
  };
