import { fromJS, Map, List } from 'immutable';
import { applicationName } from 'config';

/*
* Constants
* */

export const MODULE_NAME = 'licenses-list-component';

// Action names
const ADD = `${applicationName}/${MODULE_NAME}/ADD`;
const RESET = `${applicationName}/${MODULE_NAME}/RESET`;
const DELETE = `${applicationName}/${MODULE_NAME}/DELETE`;

/*
* Reducer
* */

export const initialState = fromJS({
  nextId: 0,
  items: [],
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD: {
      return state.withMutations((mutable) => {
        const nextId = state.get('nextId');
        const payload = List(action.payload);
        const items = state.get('items').concat(
          payload.map(({ licenseId, type, originalLicenseData }, id) => Map({
            id: nextId + id,
            licenseId,
            originalLicenseData,
            type,
          }))
        );

        mutable.set('items', items).set('nextId', nextId + payload.size);
      });
    }

    case DELETE: {
      const index = state.get('items')
        .findIndex(toast => toast.get('id') === action.payload);

      return state.set('items', state.get('items').delete(index));
    }

    case RESET:
      return initialState;

    default:
      return state;
  }
}

/*
* Actions
* */

const licensesListComponentResetDelta = () => ({ type: RESET });
const licensesListComponentAddDelta = data => ({ type: ADD, payload: data });
const licensesListComponentDeleteDelta = id => ({ type: DELETE, payload: id });


export const actions = {
  licensesListComponentResetDelta,
  licensesListComponentAddDelta,
  licensesListComponentDeleteDelta,
};
