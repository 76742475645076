import React from 'react';
import classnames from 'classnames';
import { orderStatusColors } from 'constants';

function StatusValue({ value, disabled, children }) {
  const statusOptionClassName = classnames(
    'order-status__value-icon',
    `order-status__value-icon_${orderStatusColors[value.value]}`,
    { 'order-status__value-icon_disabled': disabled },
  );

  return (
    <div className="Select-value" title={value.title}>
      <span className="Select-value-label">
        <span className={statusOptionClassName} />
        <span className="order-status__value">{ children }</span>
      </span>
    </div>
  );
}

export default StatusValue;
