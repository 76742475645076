import React, { Fragment, PureComponent } from 'react';
import FetchData from 'components/fetch-data';
import EditOrderHeader from './edit-order-header';
import EditOrderBody from './edit-order-body';


export default class EditOrderFetcher extends PureComponent {
  render() {
    const {
      match,
      orderByIdGetSignal,
      ordersDataIm,
    } = this.props;

    if (!match) {
      return null;
    }

    const orderId = parseInt(match.params.orderId, 10);

    let orderIm;
    let uid;

    if (ordersDataIm && ordersDataIm.get('data')) {
      const data = ordersDataIm.get('data');
      orderIm = data.get(0);
      uid = orderIm.get('uid');
    }

    return (
      <Fragment>
        <FetchData
          orderByIdGetSignal={orderByIdGetSignal}
          fetchActionNames={['orderByIdGetSignal']}
          fetchAttributesByActionNames={{
            orderByIdGetSignal: { id: orderId },
          }}
        >
          <Fragment>
            <EditOrderHeader uId={uid} />
            <EditOrderBody {...this.props} orderIm={orderIm} isEdit />
          </Fragment>
        </FetchData>
      </Fragment>
    );
  }
}
