import { fetchSignal } from 'ducks/fetch';
import { actions as licensesDataActions } from 'ducks/data/licenses';
import { actions as eraLicensesDataActions } from 'ducks/data/era-licenses';
import { productTypes } from 'shared/constants';

export const URL = '/api/generator';

const generatorDataGenerateSignal = ({ licenseId, data, orderId }) =>
  dispatch => Promise.coroutine(function* generateLicense() {
    const body = { ...data, licenseId };
    const answer = yield dispatch(fetchSignal(URL, { method: 'POST', body }));

    // Обновляем данные лицензий внутри заказа
    if (answer.isSuccess) {
      const isEra = data.productType === productTypes.ERA;
      const action = isEra
        ? eraLicensesDataActions.eraLicensesForOrderGetSignal
        : licensesDataActions.licensesForOrderGetSignal;

      yield dispatch(action({ id: orderId }));
    }

    return answer;
  })();

export const actions = {
  generatorDataGenerateSignal,
};
