import React, { PureComponent, Fragment } from 'react';
import i18next from 'i18next';
import classnames from 'classnames';
import Select from 'components/controls/select';
import Input from 'components/controls/input';
import InputPrice from 'components/controls/input-price';
import OrderStatus from 'components/controls/order-status';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import FetchData from 'components/fetch-data';
import {
  roles,
  licenseTypes,
  orderStatus,
  orderTypes,
  productTypes,
  currencyTypes,
  orderStatusTranslations,
  orderTypeTranslations,
  productTypeTranslations,
  headOfCommercial,
  headOfCommercialLogin,
} from 'shared/constants';
import { Divider } from 'components/divider';
import { getOrderStatus, getNewOrderStatus } from 'shared/functions';
import LicenseForm from './license-form';

const getAvailableProductType = (availableProductTypes, defaultProductType) =>
  availableProductTypes.find(productType => productType.toLowerCase() === defaultProductType.toLowerCase());

export default class OrderForm extends PureComponent {
  constructor(props) {
    super(props);

    const { orderIm, isEdit, defaultProductType = '' } = props;

    const availableProductTypes = this.getAvailableProductTypes();
    let availableProductType = getAvailableProductType(availableProductTypes, defaultProductType);

    if (availableProductType === undefined) {
      availableProductType = availableProductTypes[0];
    }

    const productType = isEdit && orderIm ? orderIm.get('productType') : availableProductType;
    const type = isEdit && orderIm ? orderIm.get('type') : orderTypes.TRIAL;
    const status = isEdit && orderIm ? orderIm.get('status') : orderStatus.PENDING;
    const partners = this.getPartners();

    this.state = {
      managers: this.getManagers(),
      partners,
      productType,
      type,
      status,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangeProductType = this.onChangeProductType.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    const { isEdit, orderIm, authDataIm } = this.props;
    const currentUserRole = authDataIm.getIn(['data', 'role']);
    const doneText = isEdit ? 'order_edited' : 'new_order_created';
    const availableProductTypes = this.getAvailableProductTypes();
    let productType = availableProductTypes.length > 1 ? this.productTypeRef.value : availableProductTypes[0];

    if (isEdit && orderIm) {
      productType = orderIm.get('productType');
    }

    let options = {
      type: this.typeRef.value,
      status: this.statusRef.value,
      productType,
    };

    if (currentUserRole === roles.ADMIN ||
      currentUserRole === roles.ACCOUNTANT ||
      currentUserRole === roles.MANAGER ||
      currentUserRole === roles.ENGINEER
    ) {
      options = {
        ...options,
        partner: this.partnerRef.value,
        manager: this.managerRef.value,
        countryId: this.countryIdRef.value,
        purchaseOrder: '-',
        invoice: this.invoiceRef.value,
        price: this.priceRef.value,
        currency: this.priceRef.currency,
        crmLink: this.crmLinkRef.value,
        description: this.descriptionRef.value,
      };
    }

    const validateOrder = this.validate(options);
    const validateLicenses = this.licenseFormRef.validate();

    if (validateOrder && validateLicenses) {
      options.licenses = this.licenseFormRef.value;

      const fields = { data: options };

      if (orderIm && orderIm.get('id')) {
        fields.id = orderIm.get('id');
        fields.uid = orderIm.get('uid');
      }

      this.props.formComponentSubmitWrapperSignal({
        submitSignal: () => this.props.submitSignal(fields),
        successSignal: () => this.props.formComponentAddSuccessSignal(),
        doneText: i18next.t(doneText, { id: fields.uid }),
      });
    }
  }

  onChangeProductType(event) {
    this.changeStateAndReset(event, 'productType');

    const isEra = event.value === productTypes.ERA;
    const options = { isUnmodified: 1, originalLicenseIds: [] };

    if (isEra) {
      this.props.eraLicensesDataForTableGetSignal(options);
    } else {
      this.props.licensesDataForTableGetSignal(options);
    }
  }

  onChangeType(event) {
    this.changeStateAndReset(event, 'type');
  }

  onChangeStatus(event) {
    this.setState({
      status: event.value,
    });
  }

  getStatus() {
    const { type } = this.state;
    const { isEdit, authDataIm, orderIm } = this.props;
    let currentUserRole = authDataIm.getIn(['data', 'role']);
    const currentUserLogin = authDataIm.getIn(['data', 'login']);

    if (currentUserLogin === headOfCommercialLogin) currentUserRole = headOfCommercial;

    if (isEdit) {
      const currentStatus = orderIm.get('status');
      // Получаем статусы доступные пользователю, при изменении заказа
      const availableOrderStatus = getOrderStatus({ type, currentUserRole, currentStatus });

      if (Array.isArray(availableOrderStatus)) {
        return availableOrderStatus.map(orderStatusName => ({
          label: i18next.t(orderStatusTranslations[orderStatusName]),
          value: orderStatusName,
        }));
      }

      return [];
    }

    // Получаем статус нового заказа
    const newOrderStatus = getNewOrderStatus({ type, currentUserRole });

    return [{
      label: i18next.t(orderStatusTranslations[newOrderStatus]),
      value: newOrderStatus,
    }];
  }

  getProductTypes() {
    return this.getAvailableProductTypes().map(value => ({
      label: i18next.t(productTypeTranslations[value]),
      value,
    }));
  }

  getAvailableProductTypes() {
    return _filter(productTypes, value => this.hasCreatingProductType(value));
  }

  getCountries() {
    const { countriesDataIm } = this.props;
    const countriesList = countriesDataIm.get('data').toJS();

    return countriesList.map(({id, name}) => ({
      value: id,
      label: i18next.t(`countries.${name}`),
    }));
  }

  // TODO: ранее скрывался HW_change при отсуствии доступных лицензий
  getTypes() {
    return _map(orderTypes, (value, name) => ({
      label: i18next.t(orderTypeTranslations[name]),
      value,
    }));
  }

  getUsers({ role, fieldName, showInactive = false }) {
    const { orderIm, isEdit, usersDataIm } = this.props;
    let currentValue;

    if (isEdit && orderIm) {
      currentValue = orderIm.get(fieldName);
    }

    return usersDataIm.get('data')
      .filter(
        user =>
          // Выбираем из активных пользователей, если неразрешено иного
          (user.get('role') === role && (user.get('active') === 1 || showInactive)) ||
          // или выбранного пользователя если даже он неактивный
          user.get('id') === currentValue
      )
      .map(user => ({
        value: user.get('id'),
        label: user.get('name'),
      }))
      // Сортируем пользователей по алфавиту
      .sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        return 0;
      })
      .toArray();
  }

  getManagers() {
    return this.getUsers({
      role: roles.MANAGER,
      fieldName: 'managerId',
    });
  }

  getPartners() {
    return this.getUsers({
      role: roles.PARTNER,
      fieldName: 'partnerId',
      showInactive: true,
    });
  }

  getInitValue() {
    const { orderIm, authDataIm, isEdit } = this.props;
    const currentUserId = authDataIm.getIn(['data', 'id']);
    const currentUserRole = authDataIm.getIn(['data', 'role']);
    const { type, productType, status } = this.state;
    const value = {
      type,
      status,
      productType,
      manager: currentUserRole === roles.MANAGER ? currentUserId : 0,
      currency: currencyTypes.RUB,
    };

    if (currentUserRole === roles.PARTNER) {
      value.partner = currentUserId;
    }

    if (isEdit && orderIm) {
      value.partner = orderIm.get('partnerId');
      value.manager = orderIm.get('managerId');
      value.purchaseOrder = '-';
      value.invoice = orderIm.get('invoice');
      value.price = orderIm.get('price');
      value.currency = orderIm.get('currency');
      value.crmLink = orderIm.get('crmLink');
      value.description = orderIm.get('description');
    }

    return value;
  }

  hasCreatingProductType(productType) {
    const { authDataIm, usersDataIm } = this.props;
    const currentUserId = authDataIm.getIn(['data', 'id']);

    return usersDataIm.get('data')
      .find(user => user.get('id') === currentUserId)
      .get('creatingProductTypes')
      .includes(productType);
  }

  changeStateAndReset(event, props) {
    this.setState({
      [props]: event.value,
    });
    this.props.licensesListComponentResetDelta();
  }

  validate(options) {
    const { authDataIm } = this.props;
    const {
      type,
      status,
      partner,
      manager,
      invoice,
      countryId,
      price: priceValue,
    } = options;
    let isValid = true;
    const price = Number(priceValue);
    const currentUserRole = authDataIm.getIn(['data', 'role']);
    const availableProductTypes = this.getAvailableProductTypes();

    if (availableProductTypes.length < 1) {
      isValid = false;
    }

    // partner
    if (type <= 0) {
      this.typeRef.error = i18next.t('v.required');
      isValid = false;
    }

    if (currentUserRole === roles.ADMIN ||
      currentUserRole === roles.ACCOUNTANT ||
      currentUserRole === roles.MANAGER ||
      currentUserRole === roles.ENGINEER
    ) {
      // partner
      if (partner <= 0) {
        this.partnerRef.error = i18next.t('v.required');
        isValid = false;
      }

      // manager
      if (manager <= 0) {
        this.managerRef.error = i18next.t('v.required');
        isValid = false;
      }

      // country ID
      if (countryId <= 0) {
        this.countryIdRef.error = i18next.t('v.required');
        isValid = false;
      }

      if (priceValue && priceValue.length > 0 && (isNaN(price) || price < 0)) {
        this.priceRef.error = i18next.t('v.invalid_format');
        isValid = false;
      }

      // Сбрасываем ошибки, чтобы они не оставались после изменения статуса
      this.invoiceRef.error = '';
      this.priceRef.error = '';

      // price
      if (type === licenseTypes.COMMERCIAL && !priceValue.length) {
        this.priceRef.error = i18next.t('v.required');
        isValid = false;
      }

      if (type === licenseTypes.COMMERCIAL && invoice <= 0) {
        this.invoiceRef.error = i18next.t('v.required');
        isValid = false;
      }

      if (status === orderStatus.DOCUMENTS_SENT) {
        // invoice
        if (!invoice.length) {
          this.invoiceRef.error = i18next.t('v.required');
          isValid = false;
        }

        // price
        if (!priceValue.length) {
          this.priceRef.error = i18next.t('v.required');
          isValid = false;
        } else if (isNaN(price) || price < 0) {
          this.priceRef.error = i18next.t('v.invalid_format');
          isValid = false;
        }
      }
    }

    return isValid;
  }

  isDisabledSubmitButton() {
    const { formComponentIm, authDataIm, orderIm, isEdit } = this.props;
    const { productType } = this.getInitValue();
    const currentUserRole = authDataIm.getIn(['data', 'role']);
    const currentStatus = isEdit && orderIm.get('status');
    const availableProductTypes = this.getAvailableProductTypes();

    let isDisabled = false;

    if (formComponentIm !== undefined) {
      isDisabled = formComponentIm.get('isDisabled');
    }

    if (availableProductTypes.length < 1) {
      isDisabled = true;
    }

    if (
      productType !== productTypes.ERA &&
      isEdit && currentUserRole !== roles.ADMIN &&
      (
        currentStatus === orderStatus.GENERATION_READY ||
        currentStatus === orderStatus.CANCELLED ||
        (
          currentStatus === orderStatus.DOCUMENTS_SENT &&
          currentUserRole !== roles.ACCOUNTANT
        )
      )
    ) {
      isDisabled = true;
    }

    return isDisabled;
  }

  render() {
    const {
      managers,
      partners,
    } = this.state;

    const {
      isEdit,
      authDataIm,
      orderIm,
      formComponentIm,
      licensesDataIm,
      eraLicensesDataIm,
      functionalitiesDataIm,
      licensesListComponentIm,
      licensesListComponentAddDelta,
      licensesListComponentResetDelta,
      licensesListComponentDeleteDelta,
      formComponentSubmitWrapperSignal,
      generatorDataGenerateSignal,
      eraLicensesDataDeactivateUpdateSignal,
      licensesDataDeactivateUpdateSignal,
      modalComponentShowDelta,
      licenseSubtypesDataIm,
      currentUserLicenseSubtypesDataIm,
      productVersionsDataIm,
      licensesDataForTableGetSignal,
      eraLicensesDataForTableGetSignal,
      licensesForOrderGetSignal,
      eraLicensesForOrderGetSignal,
      functionalitiesDataGetSignal,
      licenseSubtypesDataGetSignal,
    } = this.props;

    let licensesOrderIm;
    let licensesOriginalOrderIm;

    const currentUserRole = authDataIm.getIn(['data', 'role']);
    const {
      type,
      productType,
      status: initStatus,
      partner,
      manager,
      // purchaseOrder,
      invoice,
      price,
      currency,
      crmLink,
      description,
    } = this.getInitValue();

    const licensesIm = productType === productTypes.ERA
      ? eraLicensesDataIm
      : licensesDataIm;

    if (licensesIm && licensesIm.get('dataByOrder')) {
      licensesOrderIm = licensesIm.get('dataByOrder');
    }

    if (licensesIm && licensesIm.get('dataOriginalLicenses')) {
      licensesOriginalOrderIm = licensesIm.get('dataOriginalLicenses');
    }

    const orderStatusList = this.getStatus();
    const productTypeList = this.getProductTypes();
    const orderId = orderIm && orderIm.get('id');

    let status = initStatus;
    // Значение статуса из БД
    let currentStatus;
    let isDisabledForm = false;
    // Если статус всего один, то отключает список
    const isDisabledStatus = orderStatusList.length <= 1;
    const isDisabledProductTypes = productTypeList.length <= 1 || isDisabledForm || isEdit;
    const isShowProductTypes = productTypeList.length > 1;

    const licensesByOrderGetSignal = productType === productTypes.ERA
      ? eraLicensesForOrderGetSignal
      : licensesForOrderGetSignal;

    const fetchActionNames = [
      'functionalitiesDataGetSignal',
      'licenseSubtypesDataGetSignal',
    ];
    const fetchAttributesByActionNames = {};
    const defaultCountryId = isEdit && orderIm ? orderIm.get('countryId') : 0;

    if (isEdit) {
      currentStatus = orderIm.get('status');
      fetchActionNames.push('licensesForOrderGetSignal');
      fetchAttributesByActionNames.licensesForOrderGetSignal = { id: orderId };

      // Сверяем статусы с уже сохранённым значением, чтобы
      // в момент изменения статуса не блокировалась форма
      if (
        currentUserRole !== roles.ADMIN &&
        (
          currentStatus === orderStatus.DOCUMENTS_SENT ||
          currentStatus === orderStatus.GENERATION_READY ||
          currentStatus === orderStatus.CANCELLED
        )
      ) {
        isDisabledForm = true;
      }
    }

    // Типы заказа trial получают статус generation_ready, минуя статус pending
    if (!isEdit && type === orderTypes.TRIAL) {
      status = orderStatus.GENERATION_READY;
    }

    return (
      <form className="form">
        <div className="form__row form__row_2">
          {
            isShowProductTypes ? (
              <div className="form__field">
                <label htmlFor="order-form-product-type" className="form__label">
                  {i18next.t('product')}
                </label>
                <Select
                  id="order-form-product-type"
                  className="select_form"
                  name="form-field-product-type"
                  options={productTypeList}
                  ref={(ref) => { this.productTypeRef = ref; }}
                  clearable={false}
                  defaultValue={productType}
                  emptyValue={0}
                  disabled={isDisabledProductTypes}
                  onChange={this.onChangeProductType}
                  autoFocus={!(isDisabledForm || isEdit)}
                />
              </div>
            ) : null
          }
          <div className="form__field">
            <label htmlFor="order-form-type" className="form__label">{i18next.t('type')}</label>
            <Select
              id="order-form-type"
              className="select_form"
              name="form-field-type"
              options={this.getTypes()}
              ref={(ref) => { this.typeRef = ref; }}
              clearable={false}
              defaultValue={type}
              emptyValue={0}
              disabled={isDisabledForm || isEdit}
              onChange={this.onChangeType}
              autoFocus={!(isDisabledForm || isEdit) && isDisabledProductTypes}
            />
          </div>

          <div className="form__field">
            <label htmlFor="order-form-status" className="form__label">{i18next.t('status')}</label>
            <OrderStatus
              id="order-form-status"
              className="select_form"
              name="form-field-status"
              options={orderStatusList}
              ref={(ref) => { this.statusRef = ref; }}
              clearable={false}
              defaultValue={status}
              value={status}
              emptyValue={0}
              onChange={this.onChangeStatus}
              disabled={isDisabledStatus}
              autoFocus={!isDisabledForm && isEdit}
            />
          </div>

          {
            currentUserRole !== roles.PARTNER ? (
              <Fragment>
                <div className="form__field">
                  <label htmlFor="order-form-partner" className="form__label form__label_required">
                    {i18next.t('partner')}
                  </label>
                  <Select
                    id="order-form-partner"
                    className="select_form"
                    name="form-field-partner"
                    clearable={false}
                    options={partners}
                    ref={(ref) => { this.partnerRef = ref; }}
                    defaultValue={partner}
                    emptyValue={0}
                    disabled={isDisabledForm}
                  />
                </div>

                <div className="form__field">
                  <label htmlFor="order-form-manager" className="form__label form__label_required">
                    {i18next.t('manager')}
                  </label>
                  <Select
                    id="order-form-manager"
                    className="select_form"
                    name="form-field-manager"
                    options={managers}
                    ref={(ref) => { this.managerRef = ref; }}
                    clearable={false}
                    defaultValue={manager}
                    emptyValue={0}
                    disabled={isDisabledForm}
                  />
                </div>

                <div className="form__field">
                  <label htmlFor="order-form-country" className="form__label form__label_required">
                    {i18next.t('country')}
                  </label>
                  <Select
                    id="order-form-country"
                    className="select_form"
                    name="form-field-countryId"
                    options={this.getCountries()}
                    ref={(ref) => { this.countryIdRef = ref; }}
                    clearable={false}
                    defaultValue={defaultCountryId}
                    emptyValue={0}
                    disabled={isDisabledForm}
                  />
                </div>

                {/* // LIC-263: purchase_order removed by seller's feedback, reason: useless.
                <div className="form__field">
                  <label htmlFor="order-form-purchase" className="form__label">
                    {i18next.t('purchase_order')}
                  </label>
                  <Input
                    type="text"
                    id="order-form-purchase"
                    defaultValue={purchaseOrder}
                    ref={(ref) => { this.purchaseOrderRef = ref; }}
                    className="form__field-text form__field-text_padding"
                    placeholder="PO A / 1648"
                    disabled={isDisabledForm}
                  />
                </div> */}

                <div className="form__field">
                  <label
                    htmlFor="order-form-invoice"
                    className={classnames('form__label', {
                      form__label_required: type === licenseTypes.COMMERCIAL,
                    })}
                  >
                    {i18next.t('invoice')}
                  </label>
                  <Input
                    type="text"
                    id="order-form-invoice"
                    defaultValue={invoice}
                    ref={(ref) => { this.invoiceRef = ref; }}
                    className="form__field-text form__field-text_padding"
                    placeholder="INVOICE NO SGO-13"
                    disabled={isDisabledForm}
                  />
                </div>

                <div className="form__field">
                  <label
                    htmlFor="order-form-price"
                    className={classnames('form__label', {
                      form__label_required: status === orderStatus.DOCUMENTS_SENT || type === licenseTypes.COMMERCIAL,
                    })}
                  >
                    {i18next.t('price')}
                  </label>
                  <InputPrice
                    type="text"
                    id="order-form-price"
                    defaultValue={price}
                    defaultCurrency={currency}
                    ref={(ref) => { this.priceRef = ref; }}
                    className="form__field-text form__field-text_padding"
                    disabled={isDisabledForm}
                  />
                </div>

                <div className="form__field">
                  <label htmlFor="order-form-crm-link" className="form__label">
                    {i18next.t('crm_link')}
                  </label>
                  <Input
                    type="text"
                    id="order-form-crm-link"
                    defaultValue={crmLink}
                    ref={(ref) => { this.crmLinkRef = ref; }}
                    className="form__field-text form__field-text_padding"
                    placeholder=""
                    disabled={isDisabledForm}
                  />
                </div>
              </Fragment>
            ) : null
          }
        </div>

        {
          currentUserRole !== roles.PARTNER ? (
            <Fragment>
              <div className="form__row form__row_2">
                <div className="form-line" />
              </div>

              <div className="form__row form__row_2">
                <div className="form__field form__field_wide">
                  <label htmlFor="order-form-notes" className="form__label">
                    {i18next.t('notes')}
                    <span className="form__label-description">
                      {` (${i18next.t('invisible_for_partners')})`}
                    </span>
                  </label>
                  <textarea
                    className="form__field-text form__field-text_padding"
                    id="order-form-notes"
                    defaultValue={description}
                    placeholder="Description"
                    ref={(ref) => { this.descriptionRef = ref; }}
                    disabled={isDisabledForm}
                  />
                </div>
              </div>
            </Fragment>
          ) : null
        }

        <Divider />

        <FetchData
          licensesForOrderGetSignal={licensesByOrderGetSignal}
          functionalitiesDataGetSignal={functionalitiesDataGetSignal}
          licenseSubtypesDataGetSignal={licenseSubtypesDataGetSignal}
          fetchActionNames={fetchActionNames}
          fetchAttributesByActionNames={fetchAttributesByActionNames}
        >
          <LicenseForm
            isEdit={isEdit}
            orderType={type}
            orderId={orderId}
            orderStatus={status}
            orderCurrentStatus={currentStatus}
            currentUserRole={currentUserRole}
            productType={productType}
            disabled={isDisabledForm}
            ref={(ref) => { this.licenseFormRef = ref; }}
            licensesOrderIm={licensesOrderIm}
            functionalitiesDataIm={functionalitiesDataIm}
            licensesListComponentIm={licensesListComponentIm}
            formComponentIm={formComponentIm}
            licensesListComponentAddDelta={licensesListComponentAddDelta}
            licensesListComponentResetDelta={licensesListComponentResetDelta}
            licensesListComponentDeleteDelta={licensesListComponentDeleteDelta}
            generatorDataGenerateSignal={generatorDataGenerateSignal}
            formComponentSubmitWrapperSignal={formComponentSubmitWrapperSignal}
            modalComponentShowDelta={modalComponentShowDelta}
            licensesDataIm={licensesDataIm}
            licensesOriginalOrderIm={licensesOriginalOrderIm}
            eraLicensesDataDeactivateUpdateSignal={eraLicensesDataDeactivateUpdateSignal}
            licensesDataDeactivateUpdateSignal={licensesDataDeactivateUpdateSignal}
            eraLicensesDataIm={eraLicensesDataIm}
            licenseSubtypesDataIm={licenseSubtypesDataIm}
            currentUserLicenseSubtypesDataIm={currentUserLicenseSubtypesDataIm}
            productVersionsDataIm={productVersionsDataIm}
            eraLicensesDataForTableGetSignal={eraLicensesDataForTableGetSignal}
            licensesDataForTableGetSignal={licensesDataForTableGetSignal}
          />
        </FetchData>

        <div className="form__row form__row_2 form__row_footer">
          <div className="form__field form__field_width_auto">
            <button
              className="button button_form button_submit"
              onClick={this.onSubmit}
              disabled={this.isDisabledSubmitButton()}
            >
              {isEdit !== true ? i18next.t('create') : i18next.t('save')}
            </button>
          </div>
        </div>
      </form>
    );
  }
}
