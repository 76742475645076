import React, { PureComponent, Fragment } from 'react';
import i18next from 'i18next';
import { Table } from 'containers';
import { UserToolbar } from 'components/toolbars';
import { ShowLockingCell } from 'components/table/cell';
import { roles } from 'shared/constants';
import _map from 'lodash/map';

export default class Users extends PureComponent {
  constructor(props) {
    super(props);

    this.userRoles = _map(roles, value => ({ value, label: i18next.t(value) }));

    this.changeUserActivity = this.changeUserActivity.bind(this);
  }

  changeUserActivity(user) {
    const id = user.get('id');
    const active = user.get('active');

    if (id && active !== undefined) {
      this.props.usersDataUpdateSignal({
        id,
        data: {
          active: active ? 0 : 1,
        },
      });
    }
  }

  render() {
    const { usersDataIm } = this.props;
    const usersByActiveSelectOptions = [{
      label: i18next.t('active'),
      value: 1,
    },
    {
      label: i18next.t('inactive'),
      value: 0,
    }];

    const cells = [
      {
        id: 'login',
        getValue: model => ({
          text: model.get('login'),
          link: `/users/${model.get('id')}`,
          active: model.get('active'),
        }),
        className: 'table__cell_3',
        name: i18next.t('login'),
        sort: {
          type: 'alphabetic',
          field: 'login',
        },
        component: ShowLockingCell,
      },
      {
        id: 'name',
        getValue: model => model.get('name'),
        className: 'table__cell_4',
        name: i18next.t('staff_or_partner_name'),
        sort: {
          type: 'alphabetic',
          field: 'name',
        },
      },
      {
        id: 'role',
        getValue: model => model.get('role'),
        className: 'table__cell_3',
        name: i18next.t('role'),
        sort: {
          type: 'alphabetic',
          field: 'role',
        },
      },
      {
        id: 'manager',
        getValue: model => model.get('defaultManagerName'),
        className: 'table__cell_4',
        name: i18next.t('manager'),
        sort: {
          type: 'alphabetic',
          field: 'defaultManagerName',
        },
      },
      {
        id: 'email',
        getValue: model => model.get('email'),
        className: 'table__cell_3',
        name: i18next.t('email'),
        sort: {
          type: 'alphabetic',
          field: 'email',
        },
      },
      {
        id: 'notes',
        getValue: model => model.get('description'),
        className: 'table__cell_4',
        name: i18next.t('notes'),
        isHiddenOnClosed: true,
      },
    ];

    const filterFields = [
      {
        type: 'multiSelect',
        key: 'role',
        name: i18next.t('role'),
        items: this.userRoles,
        allowedToShowFor: [roles.ADMIN],
      },
      {
        type: 'select',
        key: 'active',
        name: i18next.t('status'),
        items: usersByActiveSelectOptions,
      },
      {
        type: 'text',
        key: 'login',
        name: i18next.t('login'),
      },
      {
        type: 'text',
        key: 'name',
        name: i18next.t('staff_or_partner_name'),
      },
      {
        type: 'text',
        key: 'email',
        name: i18next.t('email'),
      },
      {
        type: 'text',
        key: 'description',
        name: i18next.t('notes'),
      },
    ];

    const quickFilterFields = this.userRoles.map(({ value, label }) => ({
      key: value,
      name: i18next.t(label),
      values: {
        role: value,
      },
      allowedToShowFor: [roles.ADMIN],
    }));

    const actionButtons = [{
      label: user => (user.get('active') ? i18next.t('block') : i18next.t('unblock')),
      isShow: user => user.get('role') !== roles.ADMIN,
      onClick: this.changeUserActivity,
    }];

    return (
      <Fragment>
        <div className="content__head">
          <h1 className="content__title">{i18next.t('users')}</h1>
        </div>
        <UserToolbar
          tableId="users"
          filterFields={filterFields}
          quickFilterFields={quickFilterFields}
          quickFilterLabel={i18next.t('filter_by_role')}
        />
        <div className="content__body">
          <Table
            id="users"
            items={usersDataIm.get('data')}
            cells={cells}
            filterFields={filterFields}
            actionButtons={actionButtons}
          />
        </div>
      </Fragment>
    );
  }
}
