import { Map, fromJS } from 'immutable';
import { applicationName } from 'config';
import { fetchSignal } from 'ducks/fetch';

const MODULE_NAME = 'countries-data';
const URL = '/api/order-countries';

const FETCH_DONE = `${applicationName}/${MODULE_NAME}/FETCH_DONE`;
const RESET = `${applicationName}/${MODULE_NAME}/RESET`;

const initialState = Map({ data: false });

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DONE:
      return state.set('data', fromJS(action.payload));

    case RESET:
      return initialState;

    default:
      return state;
  }
}

const countriesDataResetDelta = () => ({ type: RESET });

const countriesDataGetSignal = () => dispatch => Promise.coroutine(function* getCountries() {
  const answer = yield dispatch(fetchSignal(URL));

  if (answer.isSuccess) {
    dispatch({ type: FETCH_DONE, payload: answer.data });
  }

  return answer;
})();

export const actions = {
  countriesDataResetDelta,
  countriesDataGetSignal,
};
