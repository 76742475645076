const { orderStatusMatrix, orderStatus, roles, headOfCommercial } = require('./constants');
const {
  licenseFileTypes,
  licenseSubtypes,
  productTypes,
  NUMBER_OF_DRIVES_UNLIMITED,
  FEATURES_ALL,
  VALID_HARDWARE_KEY_REX,
  VALID_ERA_HARDWARE_KEY_REX,
  VALID_ONLY_ERA_HARDWARE_KEY_REX,
  licenseModificationConditions,
  kernelTypes,
} = require('../shared/constants');

/**
 * Divides the hardware key into meaningful parts
 * @param {string} hardwareKeys Examples: "key", "key1:key2", "key1 key2", "key1:key2 key3:key4"
 * @returns {{raidix: string, era: string}[]}
 */
function splitHardwareKey(hardwareKeys) {
  return hardwareKeys.split(' ').map((hardwareKey) => {
    const keys = VALID_HARDWARE_KEY_REX.exec(hardwareKey);
    let raidix = '';
    let era = '';

    if (keys !== null) {
      [, raidix, era] = keys;
    }

    return {
      raidix,
      era,
    };
  });
}

module.exports = {
  /**
   * Возвращает список статусов заказа доступных для типа заказа(type),
   * пользователя(currentUserRole) и текущего статуса заказа(currentStatus).
   * При ошибки возвращает false. Функция используется при изменении заказа
   * так как ей необходим текущий статус заказа.
   * @param type
   * @param currentUserRole
   * @param currentStatus
   * @returns {*}
   */
  getOrderStatus({ type, currentUserRole, currentStatus }) {
    const orderStatusIds = {};
    const roleIds = {};
    const orderStatusSymbol = {};

    if (!type || !currentUserRole || !currentStatus) {
      return false;
    }

    Object.entries(orderStatus).forEach(([statusName, statusValue], id) => {
      // Сохраняем порядковый номер статуса в списке
      orderStatusIds[statusValue] = id;
      // Сохраняем названия статуса и его первую букву
      orderStatusSymbol[statusName[0]] = statusName;
    });

    Object.entries(roles).forEach(([rolesName, rolesValue], id) => {
      // Сохраняем порядковый номер роли в списке
      roleIds[rolesValue] = id;
    });

    // Получаем матрицу со статусами для конкретного типа заказа
    const orderStatusMatrixByType = orderStatusMatrix[type];

    // Проверяем существует ли тип заказа
    if (!Array.isArray(orderStatusMatrixByType)) {
      return false;
    }

    // Получаем строку матрицы. В каждом элементе списка перечислены статусы
    // (Первая буква статуса или */-) доступные для конкретной пользовательской роли
    const roleStatuses = orderStatusMatrixByType[orderStatusIds[currentStatus]];

    // Проверяем существует ли статус заказа
    if (!Array.isArray(roleStatuses)) {
      return false;
    }

    // Получаем элемент матрицы. Статусы заказа доступные
    // пользовательской роли currentUserRole
    const statusString = currentUserRole === headOfCommercial
      ? roleStatuses[roleStatuses.length -1]
      : roleStatuses[roleIds[currentUserRole]];

    // Проверка существует ли пользовательская роль
    if (statusString === undefined) {
      return false;
    }

    // Звёздочка(*) равняется всем статусам
    if (statusString === '*') {
      return Object.keys(orderStatus);
    }

    // Минус(-) сообщает что такого статуса не может быть у заказа
    if (statusString === '-') {
      return [];
    }

    // Преобразуем первые буквы статуса в полноценное название и возвращаем этот список
    return statusString.split('').map(symbol => orderStatusSymbol[symbol]);
  },

  // Изменяет все null параметры объекта на undefined
  replaceNullWithUndefined(props) {
    for (let key in props) {
      if (props[key] === null) {
        props[key] = undefined;
      }
    }
    return props;
  },

  /**
   * Возвращает начальный статус заказа с учётом роли пользователя и
   * типа создаваемого заказа. При ошибки возвращает false.
   * @param type
   * @param currentUserRole
   * @returns {*}
   */
  getNewOrderStatus({ type, currentUserRole }) {
    const roleIds = {};

    if (!type || !currentUserRole) {
      return false;
    }

    // Получаем матрицу со статусами для конкретного типа заказа
    const orderStatusMatrixByType = orderStatusMatrix[type];

    // Проверяем существует ли тип заказа
    if (!Array.isArray(orderStatusMatrixByType)) {
      return false;
    }

    Object.entries(roles).forEach(([rolesName, rolesValue], id) => {
      // Сохраняем порядковый номер роли в списке
      roleIds[rolesValue] = id;
    });

    // Порядковый номер роли текущего пользователя
    const roleId = roleIds[currentUserRole];

    for(let id in orderStatusMatrixByType) {
      if (!orderStatusMatrixByType.hasOwnProperty(id)) {
        continue;
      }

      // Получаем первый найденный статус неравный символу минус(-)
      if (orderStatusMatrixByType[id][roleId] !== '-') {
        // Преобразуем порядковый номер статуса в его название
        return Object.keys(orderStatus)[id]
      }
    }

    return false;
  },

  // TODO: С переносом фильтров на сервер можно удалить

  /**
   * Объединяем непрерывные(каждый элемент массива отличается на 1) участки массива
   * [1, 2, 3, 4, 7, 8, 9, 16] => ['1-4', '7-9', 16]
   * @param {Array} intArray
   * @returns {Array}
   */
  zipIntArray(intArray) {
    if (intArray.length === 0) {
      return [];
    }

    // Обрабатываем массив в котором больше двух элементов
    if (intArray.length <= 2) {
      return intArray;
    }

    let resultArray = [];
    // Последний непрерывный отрезок
    let lastSegment = [];

    intArray.forEach((value, id) => {
      // Добавляем новый элемент к отрезку
      lastSegment.push(value);

      // Создаём новый отрезок, если при добавлении следующего
      // элемента в массив он перестанет быть непрерывным
      if ((value + 1) !== intArray[id + 1]) {
        // Объединяем от трёх элементов
        if(lastSegment.length >= 3) {
          // Берём первый и последний элемент из текущего
          // отрезка и добавляем его к результирующему массиву
          resultArray.push(`${lastSegment[0]}-${value}`);
        } else {
          resultArray = resultArray.concat(lastSegment);
        }

        lastSegment = [];
      }
    });

    return resultArray;
  },

  /**
   * ['1-4', '7-9', 16] => [1, 2, 3, 4, 7, 8, 9, 16]
   * @param {Array} intArray
   * @returns {Array}
   */
  unzipIntArray(intArray) {
    if (intArray.length === 0) {
      return [];
    }

    let resultArray = [];

    intArray.forEach((value) => {
      if (typeof value === 'string' && value.indexOf('-') !== -1) {
        const [firstPos, lastPos] = value.split('-');
        const intFirstPos = Number(firstPos);
        // Находим длину отрезка
        // '3-7' => (7 - 3) + 1 = 5, [3, 4, 5, 6, 7].length = 5
        const segmentSize = (lastPos - intFirstPos) + 1;

        resultArray = resultArray.concat(
          Array.from(
            // Создаём массив нужной длинны по которому будем итерироваться
            Array(segmentSize).keys(),
            // Заполняем массив значениями, начиная с intFirstPos
            pos => pos + intFirstPos,
          ),
        );
      } else {
        resultArray.push(Number(value));
      }
    });

    return resultArray;
  },

  /**
   * Generates the license file name from the hardware key
   * @param {string} hardwareKey
   * @param {licenseFileTypes} [type]
   * @param {kernelTypes} kernel
   * @returns {string}
   */
  getLicenseFileName(hardwareKey, type, kernel = '') {
    // The first 8 letters of the hardware key
    const name = hardwareKey.slice(0, 8);
    const suffix = kernel === kernelTypes.NEW ? '_5.2' : '';
    const fileExtensions = {
      [licenseFileTypes.LIC]: 'lic',
      [licenseFileTypes.RPK]: 'rpk',
    };

    return licenseFileTypes[type] ? `${name}${suffix}.${fileExtensions[type]}` : hardwareKey;
  },

  hasLicenseSubtype(subtypeName) {
    return Object.keys(licenseSubtypes).includes(subtypeName);
  },

  getLicenseSubtype(subtypeCode) {
    class LicenseSubtypes {
      constructor(subtypeCode) {
        this._subtypeCode = subtypeCode;
        this._licenseSubtype = licenseSubtypes[subtypeCode];
      }

      _isEditable(field) {
        return this._licenseSubtype[field] && this._licenseSubtype[field].edit === true;
      }

      isEditableDrives() {
        return this._isEditable('drives');
      }

      isEditableSupportPeriod() {
        return this._isEditable('supportPeriod');
      }

      isEditableFeatures() {
        return this._isEditable('features');
      }

      isPremiumSupport() {
        return this._licenseSubtype.supportPeriod.premium === true;
      }

      /**
       * Are drives selected from a predefined list
       */
      isListDrives() {
        const values = this._licenseSubtype.drives.values;

        return values !== undefined && Array.isArray(values);
      }

      hasRaidLevels() {
        const values = this._licenseSubtype.raidLevels.values;

        return values !== undefined && Array.isArray(values);
      }

      hasAlwaysModifyingFields() {
        return this.getAlwaysModifyingFields().length > 0;
      }

      /**
       * Does a drive exist in a predefined drive list
       * @param drives
       * @returns {boolean}
       */
      includesDrive(drives) {
        return this.isListDrives()
          ? this._licenseSubtype.drives.values.includes(Number(drives))
          : false;
      }

      /**
       * Is the feature available for a license? The method does not check the correctness of features.
       * For example, if you pass the code "nonexistent code" for the type of license
       * to which all functions are available, then the method will return true.
       * @param {string} feature correct feature code
       */
      includesFeature(feature) {
        if (this._licenseSubtype.features.values === FEATURES_ALL) {
          return true;
        }

        return this._licenseSubtype.features.values.includes(feature);
      }

      /**
       * The maximum number of drives that a license can have
       * @returns {number}
       */
      getMaxDriveCount() {
        return this.isListDrives()
          ? Math.max(...this._licenseSubtype.drives.values)
          : NUMBER_OF_DRIVES_UNLIMITED;
      }

      /**
       * The minimum number of drives a license can have
       * @returns {number}
       */
      getMinDriveCount() {
        return this.isListDrives()
          ? Math.min(...this._licenseSubtype.drives.values)
          : 0;
      }

      /**
       * List of the available number of disks
       * @returns {Array<number>}
       */
      getListDrives() {
        return this.isListDrives()
          ? this._licenseSubtype.drives.values
          : [];
      }

      getDefaultSupportPeriod() {
        return this._licenseSubtype.supportPeriod.default;
      }

      getRaidLevels() {
        return this._licenseSubtype.raidLevels.values;
      }

      getSubtypeCode() {
        return this._subtypeCode;
      }

      getAlwaysModifyingFields() {
        const { modificationConditions = {} } = this._licenseSubtype;
        const result = [];

        Object.entries(modificationConditions).forEach(([name, value]) => {
          if (value === licenseModificationConditions.ANY) {
            result.push(name);
          }
        });

        return result;
      }
    }

    return new LicenseSubtypes(subtypeCode);
  },

  /**
   * Checks if the keys are similar, ignore the last 8 characters of the key
   * @param hardwareKey
   * @param originalHardwareKey
   * @returns {boolean}
   */
  similarityRaidixHardwareKey(hardwareKey, originalHardwareKey) {
    const [{ raidix: raidixHardwareKey }] = splitHardwareKey(hardwareKey);
    const [{ raidix: raidixOriginalHardwareKey }] = splitHardwareKey(originalHardwareKey);

    // LIC-186
    const insignificantPartOfKey = 8;

    return raidixHardwareKey.slice(0, -insignificantPartOfKey) ===
      raidixOriginalHardwareKey.slice(0, -insignificantPartOfKey);
  },
  splitHardwareKey,

  isNonHardwareKeyValidation(hardwareKey, productType, isEra) {
    if (productType === productTypes.ERA) {
      return !VALID_ONLY_ERA_HARDWARE_KEY_REX.test(hardwareKey);
    }

    return (
      (!isEra && !VALID_HARDWARE_KEY_REX.test(hardwareKey)) ||
      (isEra && !VALID_ERA_HARDWARE_KEY_REX.test(hardwareKey))
    );
  },

  isEmailValidation(email) {
    if (!email) {
      return false;
    }

    const emailParts = email.split('@');

    if (emailParts.length !== 2) {
      return false;
    }

    const [account, address] = emailParts;

    if (account.length > 64 || address.length > 255) {
      return false;
    }

    const domainParts = address.split('.');

    if (domainParts.some((part) => part.length > 63)) {
      return false;
    }

    return /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(email);
  },
};
