import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import i18next from 'i18next';
import LockIcon from 'img/lock.svg';
import TableCell from './cell';

export default function ShowLockingCell({ value, className }) {
  return (
    <TableCell className={className}>
      <div className="sticky-icon">
        <div className="sticky-icon__text">
          <Link to={value.link}>{value.text}</Link>
        </div>
        {
          !value.active ? (
            <Fragment>
              <div className="sticky-icon__icon sticky-icon__icon_gray">
                <LockIcon
                  width="14"
                  height="14"
                  data-tip={i18next.t('server.user_blocked')}
                  data-for={`user${value.text}`}
                />
              </div>
              <ReactTooltip
                id={`user${value.text}`}
                effect="solid"
                delayHide={200}
              />
            </Fragment>
          ) : null
        }
      </div>
    </TableCell>
  );
}
