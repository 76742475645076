import React, { PureComponent } from 'react';
import i18next from 'i18next';
import ReactTooltip from 'react-tooltip';
import QuickFilterButton from './quick-filter-button';

export default class QuickFilter extends PureComponent {
  render() {
    const {
      id,
      authDataIm,
      tableComponentIm,
      quickFilterFields,
      quickFilterLabel,
      tableComponentChangeQuickFiltersDelta,
      tableComponentChangeQuickFiltersDeltaAsync,
      tableComponentResetFiltersDelta,
      tableComponentResetFiltersDeltaAsync,
      filterSignal,
    } = this.props;
    const role = authDataIm.getIn(['data', 'role']);
    const permittedQuickFilterFields = quickFilterFields.filter(
      ({ allowedToShowFor }) => allowedToShowFor === undefined ||
        (allowedToShowFor && allowedToShowFor.indexOf(role) !== -1)
    );

    const label = quickFilterLabel !== undefined ? quickFilterLabel : i18next.t('filter_by');

    return (
      permittedQuickFilterFields.length > 0 ? (
        <div className="controls-group">
          <div className="controls-group__control">
            <span>{label}:</span>
          </div>
          {
            permittedQuickFilterFields.map(filter => (
              <QuickFilterButton
                {...filter}
                id={id}
                buttonId={filter.key}
                tableComponentIm={tableComponentIm}
                tableComponentChangeQuickFiltersDelta={tableComponentChangeQuickFiltersDelta}
                tableComponentResetFiltersDelta={tableComponentResetFiltersDelta}
                tableComponentResetFiltersDeltaAsync={tableComponentResetFiltersDeltaAsync}
                tableComponentChangeQuickFiltersDeltaAsync={tableComponentChangeQuickFiltersDeltaAsync}
                filterSignal={filterSignal}
              />
            ))
          }
          <ReactTooltip
            id="quickFilter"
            effect="solid"
            delayHide={200}
            multiline
          />
        </div>
      ) : null
    );
  }
}
