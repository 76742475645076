import React, { PureComponent } from 'react';
import _map from 'lodash/map';
import numeral from 'numeral';
import { currencyTypes, currencySymbols } from 'constants';
import Input from 'components/controls/input';
import { PRICE_FORMAT } from 'shared/constants';
import ReactTooltip from 'react-tooltip';
import CurrencyButton from './currency-button';

export default class InputPrice extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currency: props.defaultCurrency,
    };

    this.onChangeCurrency = this.onChangeCurrency.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
  }

  onChangeCurrency({ currency }) {
    if (this.props.disabled !== true) {
      this.setState({ currency });
    }
  }

  onChangeValue(event) {
    const { value } = event.target;
    // Убираем все символы кроме цифр и точки
    let newValue = value.replace(/[^0-9.]+/g, '');
    // Заменяем все точки на пустые строки после первой найденной точки
    const firstDotIndex = newValue.indexOf('.');

    if (firstDotIndex !== -1) {
      newValue = newValue.substring(0, firstDotIndex + 1) + newValue.substring(firstDotIndex + 1).replace(/\./g, '');
    }

    // Ограничиваем количество знаков после точки до двух
    newValue = newValue.replace(/(\.\d{2})\d+/, '$1');

    this.inputRef.value = newValue;
  }

  get value() {
    return this.inputRef.value.replace(/[^0-9.]/g, '');
  }

  get currency() {
    return this.state.currency;
  }

  set value(newValue) {
    // Применяем новое значения с учётом форматирования
    this.inputRef.value = this.formatValue(newValue);
  }

  set error(newValue) {
    this.inputRef.error = newValue;
  }

  getCurrencyTypes() {
    const { currency } = this.state;

    return _map(currencyTypes, (currencyName, currencyCode) => {
      const currencySymbol = currencySymbols[currencyCode];
      const active = currencyName === currency;

      return (
        <CurrencyButton
          key={currencyName}
          id={currencyName}
          active={active}
          disabled={this.props.disabled}
          onClick={this.onChangeCurrency}
        >
          {currencySymbol}
        </CurrencyButton>
      );
    });
  }

  formatValue(value) {
    if (value === undefined || value === null) {
      return value;
    }

    return numeral(value).format(PRICE_FORMAT);
  }

  render() {
    const {
      defaultValue,
      placeholder = '18,240,629.00',
    } = this.props;

    const props = {
      ...this.props,
      className: 'form__field-text form__field-text_currency',
      errorClassName: 'input-component__error_price',
      defaultValue: this.formatValue(defaultValue),
      ref: (ref) => { this.inputRef = ref; },
      onChange: this.onChangeValue,
      placeholder,
      maxLength: 15,
    };

    return (
      <div className="input-price-component form__field-text_padding">
        <Input {...props} />
        <div className="input-price-component__currencies">
          {this.getCurrencyTypes()}
        </div>
        <ReactTooltip
          id="currency"
          effect="solid"
          delayHide={200}
        />
      </div>
    );
  }
}
