import React from 'react';
import Price from 'components/controls/price';
import TableCell from './cell';

export default function OrderStatusCell({ value, className }) {
  return (
    <TableCell className={className}>
      <Price price={value.price} currency={value.currency} />
    </TableCell>
  );
}
