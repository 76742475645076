export function getRelatedFeatures(functionalitiesDataIm, selectFeature) {
  const relatedFeature = {};
  const relatedFeatureIds = [];

  functionalitiesDataIm.get('data').forEach((feature) => {
    const relatedFrom = feature.get('relatedFrom');

    if (relatedFrom.size > 0) {
      const id = feature.get('id');

      relatedFeatureIds.push(id);
      relatedFeature[id] = feature.get('relatedFrom').toJS();
    }
  });

  return selectFeature.reduce((sum, value) => {
    if (relatedFeatureIds.indexOf(value) !== -1) {
      return sum.concat(relatedFeature[value]);
    }

    return sum;
  }, []);
}

export function getOriginalLicenseIds(licenses) {
  return licenses.get('items').reduce((acc, license) => {
    const originalLicenseData = license.get('originalLicenseData');

    if (originalLicenseData && originalLicenseData.get('id')) {
      acc.push(originalLicenseData.get('id'));
    }

    return acc;
  }, []);
}
