import React, { Fragment } from 'react';
import numeral from 'numeral';
import { PRICE_FORMAT } from 'shared/constants';
import { currencySymbols } from 'constants';

export default function Price({ price, currency }) {
  let value = '\u2014';

  if (price !== undefined) {
    const format = numeral(price).format(PRICE_FORMAT);

    value = `${format} ${currencySymbols[currency]}`;
  }

  return (
    <Fragment>{value}</Fragment>
  );
}
