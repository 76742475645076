import React, { PureComponent, Fragment } from 'react';
import i18next from 'i18next';
import moment from 'moment';
import { TableAsync } from 'containers';
import browserHistory from 'browser-history';
import { CSV_FILE_NAME_DATE_FORMAT } from 'constants';
import { LicenseToolbar } from 'components/toolbars';
import { productTypes } from 'shared/constants';
import {
  getNoSupportFilter,
  getExpiringSupportFilter,
  getIssueDateFilter,
  getNotCanceledFilter,
} from './licenses-quick-filters';

export default class LicensesPage extends PureComponent {
  constructor(props) {
    super(props);

    this.onExportToFile = this.onExportToFile.bind(this);
  }

  onExportToFile() {
    const { productType, licensesCsvDataGetSignal } = this.props;
    const fileName = `${productType.toLowerCase()}_licenses_${moment().format(CSV_FILE_NAME_DATE_FORMAT)}`;

    this.licensesTableRef
      .getWrappedInstance()
      .exportToFile({
        productType,
        dataGetSignal: licensesCsvDataGetSignal,
        fileName,
      });
  }

  render() {
    const {
      licensesDataIm,
      authDataIm,
      tableId,
      filterFields,
      eraLicensesDataForTableGetSignal,
      licensesDataForTableGetSignal,
      cells,
      productType,
    } = this.props;

    const isEraLicenses = productType === productTypes.ERA;
    const pagination = licensesDataIm.get('totalCount');

    const updateLicensesDataSignal = isEraLicenses
      ? eraLicensesDataForTableGetSignal
      : licensesDataForTableGetSignal;

    const now = new Date();
    // Первая секунда текущего дня
    const currentDay = moment(
      `${now.getDate()}.${now.getMonth() + 1}.${now.getFullYear()}`,
      'DD.MM.YYYY',
    );
    const formatCurrentDay = currentDay.format('X');

    const quickFilterFields = [
      getNoSupportFilter(formatCurrentDay),
      getExpiringSupportFilter(currentDay, authDataIm),
      getIssueDateFilter(),
      getNotCanceledFilter(),
    ];

    const actionButtons = [{
      label: i18next.t('history'),
      onClick: license => browserHistory.push(`/licenses/history/${productType.toLowerCase()}/${license.get('id')}`),
    }];

    return (
      <Fragment>
        <LicenseToolbar
          tableId={tableId}
          filterFields={filterFields}
          quickFilterFields={quickFilterFields}
          authDataIm={authDataIm}
          onExportToFile={this.onExportToFile}
          updateLicensesDataSignal={updateLicensesDataSignal}
        />
        <div className="content__body">
          <TableAsync
            actionButtons={actionButtons}
            cells={cells}
            filterFields={filterFields}
            id={tableId}
            items={licensesDataIm.get('data')}
            pagination={pagination}
            quickFilterFields={quickFilterFields}
            ref={(ref) => { this.licensesTableRef = ref; }}
            tableGetSignal={updateLicensesDataSignal}
          />
        </div>
      </Fragment>
    );
  }
}
