/*
* Component with two datepickers - from and to.
* His value is an array with "from" and "to" timestamps
* If no one date is picked - value returns undefined
* */
import React, { PureComponent, Fragment } from 'react';
import moment from 'moment';
import i18next from 'i18next';
import DatePicker from 'react-datepicker';
import Input from 'components/controls/input';

export default class DateInterval extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { ...this.values(props.defaultValue) };
    this.isRow = props.isRow;

    this.onChangeStart = this.onChangeStart.bind(this);
    this.onChangeEnd = this.onChangeEnd.bind(this);
    this.onWithoutValue = this.onWithoutValue.bind(this);
    this.onClearValue = this.onClearValue.bind(this);
    this.onChangeRaw = this.onChangeRaw.bind(this);
  }

  onChangeStart(value) {
    this.setState({ startDate: value });
  }

  onChangeEnd(value) {
    this.setState({ endDate: value });
  }

  onWithoutValue(event) {
    event.preventDefault();

    this.setState({
      startDate: -1,
      endDate: -1,
    });
  }

  onClearValue(event) {
    event.preventDefault();

    this.setState({ ...this.values(this.props.emptyValue) });
  }

  onChangeRaw(event) {
    if (event.target.value.indexOf('-') !== -1) {
      this.onWithoutValue(event);
    }
  }

  get value() {
    let fromValue = Number(this.state.startDate) || undefined;
    let toValue = Number(this.state.endDate) || undefined;

    if (fromValue === -1 || toValue === -1) {
      return [-1, -1];
    }

    if (!fromValue && !toValue) {
      return undefined;
    }

    if (fromValue) {
      // Удаляем миллисекунды
      fromValue /= 1000;
    }

    // TODO: если в toValue уже содержится последняя
    // секунда выбраного дня, то дабавляется лишний день
    if (toValue) {
      // Это должна быть последняя секунда выбранного дня
      toValue = Number(
        moment(Number(toValue), 'x')
          .add(23, 'hours')
          .add(59, 'minutes')
          .add(59, 'seconds')
          .format('X')
      );
    }

    return [fromValue, toValue];
  }

  set value(values) {
    this.setState({ ...this.values(values) });
  }

  values(values) {
    if (!Array.isArray(values)) {
      return { startDate: undefined, endDate: undefined };
    }

    let [startDate, endDate] = values;

    if (startDate === -1 || endDate === -1) {
      startDate = -1;
      endDate = -1;
    } else {
      startDate = startDate ? moment(startDate, 'X') : undefined;
      endDate = endDate ? moment(endDate, 'X') : undefined;
    }

    return {
      startDate,
      endDate,
    };
  }

  render() {
    const props = {
      dateFormat: 'D.M.YYYY',
      placeholderText: i18next.t('placeholder.date'),
      className: 'form__field-text',
      popperClassName: 'react-datepicker-popper_triangle_right',
      disabledKeyboardNavigation: true,
      popperModifiers: {
        offset: {
          enabled: true,
          // Смещаем календарь чтобы он не выезжал за грань
          // окна и не создавал горизонтальный скрол
          offset: '-70px, 0px',
        }
      },
    };

    return (
      <div className={`date-interval ${!this.isRow && 'date-interval_filter'}`}>
        {
          this.state.startDate !== -1 && this.state.endDate !== -1 ? (
            <Fragment>
              <DatePicker
                {...props}
                selectsStart
                selected={this.state.startDate}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.onChangeStart}
                onChangeRaw={this.onChangeRaw}
              >
                <div className="react-datepicker__footer">
                  <button className="button button_wide" onClick={this.onWithoutValue}>
                    {i18next.t('date_is_not_set')}
                  </button>
                </div>
              </DatePicker>
              <div className="date-interval__separator">{'\u2014'}</div>
              <DatePicker
                {...props}
                selectsEnd
                selected={this.state.endDate}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.onChangeEnd}
                onChangeRaw={this.onChangeRaw}
              >
                <div className="react-datepicker__footer">
                  <button className="button button_wide" onClick={this.onWithoutValue}>
                    {i18next.t('date_is_not_set')}
                  </button>
                </div>
              </DatePicker>
            </Fragment>
          ) : (
            <div className="position-wrapper position-wrapper_wide">
              <Input
                type="text"
                className="form__field-text"
                defaultValue={i18next.t('date_is_not_set')}
                readOnly
                tabIndex={-1}
              />
              <button
                className="button button_input"
                onClick={this.onClearValue}
              >
                {i18next.t('reset')}
              </button>
            </div>
          )
        }
      </div>
    );
  }
}
