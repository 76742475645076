import React, { PureComponent } from 'react';
import i18next from 'i18next';
import classnames from 'classnames';

const generatedPasswordLength = 12;
const generatePassword = x => Math.floor(Math.random()
  * Date.now()).toString(36).slice(0, x);

export default class PasswordButton extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    e.preventDefault();

    this.props.onClick({ password: generatePassword(generatedPasswordLength) });
  }

  render() {
    const { active, disabled, children } = this.props;
    const className = classnames(
      'button',
      'button_password',
      { 'button_currency-active button_non-interactive': active },
    );

    return (
      <button
        className={className}
        onClick={this.onChange}
        data-tip={i18next.t('generate_password')}
        data-for="generate_password"
        tabIndex={(active || disabled) ? -1 : null}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }
}
