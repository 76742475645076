import { connect } from 'react-redux';
import Analytics from 'components/pages/analytics';
import dataFetcherEnhance from 'components/data-fetcher-enhance';
import { actions as analyticsDataActions } from 'ducks/data/analytics';
import { actions as countriesDataActions } from 'ducks/data/countries';
import { actions as licensesDataActions } from 'ducks/data/licenses';

const mapDispatchToProps = Object.assign(
  {},
  analyticsDataActions,
  countriesDataActions,
  licensesDataActions,
);

function mapStateToProps(state) {
  return {
    authDataIm: state.data.authDataIm,
    analyticsDataIm: state.data.analyticsDataIm,
    countriesDataIm: state.data.countriesDataIm,
    fetchActionNames: [
      'countriesDataGetSignal',
    ],
  };
}

const getCountriesWithFetch = dataFetcherEnhance(Analytics);

export default connect(mapStateToProps, mapDispatchToProps)(getCountriesWithFetch);
