import React from 'react';

export default function IconButton({ icon: Icon, text, children, ...props }) {
  return (
    <button className="button button_icon" {...props}>
      <span className="button__icon">
        <Icon
          width="16"
          height="16"
        />
      </span>
      <span className="button__text">
        {text}
      </span>
      {children}
    </button>
  );
}
