import React, { PureComponent } from 'react';
import i18next from 'i18next';
import DateInterval from 'components/table-filter/date-interval';
import Select from 'react-select';
import { productTypes, productTypeTranslations } from 'shared/constants';
import DownloadIcon from 'img/download.svg';
import IconButton from 'components/controls/icon-button';
import fileSaver from 'file-saver';
import moment from 'moment';
import { CSV_FILE_NAME_DATE_FORMAT } from 'constants';

export default class AnalyticsForm extends PureComponent {
  constructor(props) {
    super(props);

    this.licenseOptions = Object.keys(productTypes).map(key => ({
      label: i18next.t(productTypeTranslations[key]), value: productTypes[key]
    }));

    this.countriesOptions = this.props.countries.map(countrie => ({
      label: i18next.t(`countries.${countrie.name}`),
      value: countrie.id,
    }));

    this.state = {
      licenseType: productTypes.RAIDIX,
      countries: undefined,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeLicenseType = this.onChangeLicenseType.bind(this);
    this.onChangeCountrie = this.onChangeCountrie.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    const data = {
      filters: { generatedDate: this.date.value },
      isEra: this.state.licenseType === productTypes.ERA,
      locale: this.props.locale,
    };

    if (this.state.countries && this.state.countries.length > 0) {
      data.filters.countryId = this.state.countries.map(countrie => countrie.value);
    }

    this.props.formComponentSubmitWrapperSignal({
      submitSignal: () => this.props.submitSignal({ data }),
      successSignal: () => this.props.formComponentAddSuccessSignal(),
      doneText: i18next.t('analysis_report_created'),
    });
  }

  async onSave(e) {
    e.preventDefault();

    const { saveSignal } = this.props;
    const data = {
      analytics: true,
      productType: this.state.licenseType,
      filters: {},
    };

    if (this.date.value && this.date.value.length > 0) {
      data.filters.generatedDate = this.date.value;
    }

    if (this.state.countries && this.state.countries.length > 0) {
      data.filters.countryId = this.state.countries.map(countrie => countrie.value);
    }

    const result = await saveSignal({ data });

    if (result && result.data.content) {
      const fileName = `${data.productType.toLowerCase()}_licenses_${moment().format(CSV_FILE_NAME_DATE_FORMAT)}`;

      // eslint-disable-next-line no-undef
      const licenseBlob = new Blob(
        // Add \ufeff so that excel opens csv in the correct encoding (UTF-8)
        ['\ufeff', result.data.content],
        { type: 'octet/stream' },
      );

      fileSaver.saveAs(licenseBlob, `${fileName}.csv`);
    }
  }

  onChangeLicenseType(event) {
    this.setState({
      licenseType: event.value,
    });
  }

  onChangeCountrie(options) {
    this.setState({
      countries: options,
    });
  }

  render() {
    return (
      <form className="form">
        <div className="form__row_gap-15">
          <button
            className="button button_form button_submit form_row__submit"
            onClick={this.onSubmit}
          >
            {i18next.t('analyse')}
          </button>

          <label className="form__label form__label_row form__field_mr-0">
            {i18next.t('license_type')}:
          </label>
          <Select
            name="form-license-type"
            value={this.state.licenseType}
            options={this.licenseOptions}
            clearable={false}
            searchable={false}
            id="license_type"
            className="select_form form__field_mr-0"
            style={{ width: '120px' }}
            onChange={this.onChangeLicenseType}
          />

          <label className="form__label form__label_row form__field_mr-0">
            {i18next.t('creation_date')}:
          </label>
          <DateInterval
            ref={(ref) => { this.date = ref; }}
            defaultValue={undefined}
            emptyValue={undefined}
            isRow
          />

          <label className="form__label form__label_row form__field_mr-0">
            {i18next.t('countries_label')}:
          </label>
          <Select
            multi
            name="form-countrie"
            value={this.state.countries}
            options={this.countriesOptions}
            id="countrie"
            className="select_form form__field_mr-0"
            style={{ width: '270px' }}
            placeholder={i18next.t('all')}
            onChange={this.onChangeCountrie}
          />

          <div className="form__row_btns">
            <IconButton
              icon={DownloadIcon}
              onClick={this.onSave}
              text={i18next.t('export_to_csv')}
            />
          </div>
        </div>
      </form>
    );
  }
}
