import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import Chip from 'components/chip';
import { DATE_FORMAT } from 'shared/constants';
import DateCell from './date';

export default function SupportEndDateCell({ value, valueRaw, className }) {
  let validationClass = 'chip_none';
  let result = '\u2014';

  if (valueRaw) {
    // Получаем текущее время
    // TODO: Учитывать часовой пояс
    const currentTime = (new Date()).getTime();
    const supportEndTime = moment(valueRaw, 'X');
    const perMonth = moment(supportEndTime).subtract(30, 'days');

    validationClass = classnames({
      // Если осталось больше 30 дней
      chip_none: currentTime < perMonth,
      // Если осталось меньше 30 дней
      chip_yellow: currentTime > perMonth && currentTime < supportEndTime,
      // Если потдержка закончилась
      chip_red: currentTime > supportEndTime,
    });

    result = supportEndTime.format(DATE_FORMAT);
  }

  return (
    <DateCell className={className} value={value}>
      {
        valueRaw ? (
          <Chip className={validationClass} title={result}>{result}</Chip>
        ) : value
      }
    </DateCell>
  );
}
