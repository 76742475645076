import React, { Fragment } from 'react';
import i18next from 'i18next';

export default function PaginationControls({
  currentPage,
  itemsPerPage,
  itemsCount,
  pagesCount,
  isDisabledPrevious,
  isDisabledNext,
  onPreviousClick,
  onNextClick,
}) {
  const firstItemOnPage = (currentPage - 1) * itemsPerPage;
  let lastItemOnPage;

  if (itemsCount < itemsPerPage) {
    lastItemOnPage = itemsCount;
  } else if (currentPage < pagesCount) {
    lastItemOnPage = currentPage * itemsPerPage;
  } else {
    lastItemOnPage = itemsCount;
  }

  return (
    <Fragment>
      <div className="table__pagination-count">
        {i18next.t('pagination.show')}:{' '}
        <span className="table__pagination-count-value">{firstItemOnPage + 1}</span> — {' '}
        <span className="table__pagination-count-value">
          {lastItemOnPage}
        </span>
        {' '} {i18next.t('pagination.of')} {' '}
        <span className="table__pagination-count-value">{itemsCount}</span>
      </div>
      <div className="table__pagination-buttons">
        <input
          type="button"
          className="button table__pagination-button"
          value={`\u2190 ${i18next.t('pagination.previous_text')}`}
          title={i18next.t('pagination.previous_title')}
          disabled={isDisabledPrevious}
          onClick={onPreviousClick}
        />
        <input
          type="button"
          className="button table__pagination-button"
          value={`${i18next.t('pagination.next_text')} \u2192`}
          title={i18next.t('pagination.next_title')}
          disabled={isDisabledNext}
          onClick={onNextClick}
        />
      </div>
    </Fragment>
  );
}
