import { applicationName } from 'config';
import { actions as toastsComponentActions } from 'ducks/components/toasts';
import { actions as modalComponentActions } from 'ducks/components/modal';
import { Map } from 'immutable';
import browserHistory from 'browser-history';

/*
* Constants
* */

export const MODULE_NAME = 'form-component';

// Action names
const DISABLE = `${applicationName}/${MODULE_NAME}/DISABLE`;
const ENABLE = `${applicationName}/${MODULE_NAME}/ENABLE`;

/*
* Reducer
* */

const initialState = Map();

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ENABLE: {
      return state.mergeDeep({
        [action.payload.id]: {
          isDisabled: false,
        },
      });
    }

    case DISABLE: {
      return state.mergeDeep({
        [action.payload.id]: {
          isDisabled: true,
        },
      });
    }

    default: {
      return state;
    }
  }
}

const formComponentDisableDelta = id => ({ type: DISABLE, payload: { id } });
const formComponentEnableDelta = id => ({ type: ENABLE, payload: { id } });

const formComponentSubmitWrapperSignal = ({ id, submitSignal, successSignal, doneText = false }) =>
  dispatch => Promise.coroutine(function* getWrapper() {
    dispatch(formComponentDisableDelta(id));

    let answer = yield submitSignal({ force: false });

    // If this action required confirmation
    if (answer.data && answer.data.confirmationDialog) {
      // Show modal confirmation dialog box
      const isResend = yield dispatch(() => new Promise((resolve) => {
        dispatch(modalComponentActions.modalComponentShowDelta('confirm', {
          ...answer.data.confirmationDialog,
          resolve,
        }));
      }));

      // If the user has agreed to send
      // the same request with the flag force
      if (isResend) {
        answer = yield submitSignal({ force: true });
      }
    }

    if (answer.isSuccess) {
      if (doneText) {
        dispatch(
          toastsComponentActions.toastsComponentAddDelta({ type: 'info', content: doneText })
        );
      }

      if (successSignal) {
        yield successSignal();
      }
    }

    dispatch(formComponentEnableDelta(id));

    return answer;
  })();

// TODO: Можно ли отказаться от двойного вызова функции
const formComponentAddSuccessSignal = () => () => (new Promise((resolve) => {
  // /user/add -> /user
  const path = browserHistory.location.pathname.split('/').slice(0, 2).join('/');

  browserHistory.replace(path);
  resolve();
}));

export const actions = {
  formComponentSubmitWrapperSignal,
  formComponentAddSuccessSignal,
};
