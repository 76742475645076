import React, { PureComponent, Fragment } from 'react';
import i18next from 'i18next';
import classnames from 'classnames';
import { ITEMS_PER_PAGE_OPTIONS } from 'constants';
import Preloader from 'components/preloader';
import fileSaver from 'file-saver';
import TableHeader from './header';
import TableRow from './row';
import Pagination from './pagination';

const EmptyTableContent = ({ isEmpty }) => (
  <div className="table__row table__row_empty">
    <div className="empty-table">
      <div className="empty-table__title">
        {isEmpty ? i18next.t('no_data_here') : i18next.t('filter_results_empty')}
      </div>
      {isEmpty ?
        null :
        <div className="empty-table__text">{i18next.t('change_filter_parameters')}</div>
      }
    </div>
  </div>
);

const TableContent = ({ items, cells, onClickRow, actionButtons }) => (
  <Fragment>
    {items.map(item => (
      <TableRow
        key={item.get('id')}
        item={item}
        cells={cells}
        onClickRow={onClickRow}
        actionButtons={actionButtons}
      />
    ))}
  </Fragment>
);

const getCellsByRole = (cells, role) => {
  const shownCells = [];

  cells.forEach((cell) => {
    // Отфильтровываем колонки на которые не хватает прав
    if (cell.allowedToShowFor && cell.allowedToShowFor.indexOf(role) === -1) {
      return;
    }

    if (cell.isShow !== undefined && !cell.isShow) {
      return;
    }

    shownCells.push(cell);
  });

  return shownCells;
};

export default class Table extends PureComponent {
  constructor(props) {
    super(props);
    this.exportToFile = this.exportToFile.bind(this);
    this.onChangePageAsync = this.onChangePageAsync.bind(this);
    this.onChangeItemsPerPageAsync = this.onChangeItemsPerPageAsync.bind(this);
    this.onSortAsync = this.onSortAsync.bind(this);
  }

  componentWillUnmount() {
    this.props.tableComponentResetDelta(this.props.id);
  }

  /**
   * Async Actions
  */
  async onChangePageAsync(tableId, currentPage) {
    const { tableComponentChangePageDeltaAsync, tableGetSignal, signalAttributes } = this.props;

    await tableComponentChangePageDeltaAsync(tableGetSignal, {
      id: tableId,
      page: currentPage,
      signalAttributes,
    });
  }

  async onChangeItemsPerPageAsync(tableId, pageSize) {
    const { tableComponentChangeItemsPerPageDeltaAsync, tableGetSignal, signalAttributes } = this.props;

    await tableComponentChangeItemsPerPageDeltaAsync(tableGetSignal, { id: tableId, pageSize, signalAttributes });
  }

  async onSortAsync(tableId, sort) {
    const { tableComponentSortChangeDeltaAsync, tableGetSignal, signalAttributes } = this.props;
    await tableComponentSortChangeDeltaAsync(tableGetSignal, { id: tableId, sort, signalAttributes });
  }

  async exportToFile({ dataGetSignal, productType, fileName = 'export' }) {
    const { items, tableComponentIm } = this.props;
    const filters = tableComponentIm && tableComponentIm.get('filters');
    const filtersToObj = filters ? filters.toJS() : {};

    // Если нечего экспортировать
    if (items.size === 0) {
      return;
    }

    const result = await dataGetSignal({
      data: {
        productType,
        filters: filtersToObj,
      }
    });

    if (result && result.data.content) {
      // eslint-disable-next-line no-undef
      const licenseBlob = new Blob(
        // Add \ufeff so that excel opens csv in the correct encoding (UTF-8)
        ['\ufeff', result.data.content],
        { type: 'octet/stream' },
      );

      // Запускаем скачивание
      fileSaver.saveAs(licenseBlob, `${fileName}.csv`);
    }
  }

  render() {
    const {
      id,
      cells,
      items,
      tableComponentIm,
      authDataIm,
      pagination,
      className,
      onClickRow,
      actionButtons,
    } = this.props;

    const minItemsPerPage = ITEMS_PER_PAGE_OPTIONS[0];

    const role = authDataIm.getIn(['data', 'role']);
    const sort = tableComponentIm && tableComponentIm.get('sort');
    const page = (tableComponentIm && tableComponentIm.get('page')) || 1;
    const itemsPerPage = (tableComponentIm && tableComponentIm.get('itemsPerPage')) || minItemsPerPage;
    const loading = (tableComponentIm && tableComponentIm.get('loading')) || this.props.loading;
    const itemsSize = pagination || 0;

    const shownCells = getCellsByRole(cells, role);

    const classNameTable = loading ? 'table__disable' : '';

    return (
      <div className={classnames('table', className)}>
        <TableHeader
          tableId={id}
          cells={shownCells}
          currentPage={page}
          itemsPerPage={itemsPerPage}
          sortChangeAction={this.onSortAsync}
          isEmpty={itemsSize === 0}
          currentSort={sort}
          actionButtons={actionButtons}
        />

        <div className={classNameTable}>
          {loading && <Preloader />}

          {itemsSize > 0 ?
            <TableContent
              items={items}
              cells={shownCells}
              onClickRow={onClickRow}
              actionButtons={actionButtons}
            /> :
            <EmptyTableContent isEmpty={itemsSize === 0} />
          }
        </div>

        {itemsSize > 0 ?
          <div className="table__footer">
            {itemsSize > minItemsPerPage ?
              <Pagination
                tableId={id}
                changePage={this.onChangePageAsync}
                currentPage={page}
                itemsCount={itemsSize}
                itemsPerPage={itemsPerPage}
                changeItemsPerPage={this.onChangeItemsPerPageAsync}
              /> :
              null
            }
          </div> :
          null
        }
      </div>
    );
  }
}
