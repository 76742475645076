import React from 'react';
import i18next from 'i18next';
import BackLink from 'components/controls/back-link';

export default ({ uId = '' }) => (
  <div className="content__head">
    <BackLink
      text={i18next.t('back_to_list_of_orders')}
      to="/orders"
    />
    <h1 className="content__title">
      {i18next.t('editing_order')}
      <span className="content__subtitle"> #{uId}</span>
    </h1>
  </div>
);
