import React, { PureComponent, Fragment } from 'react';
import { Modal } from 'containers';
import { licenseCreateTypes } from 'constants';
import { orderTypes, productTypes } from 'shared/constants';
import { LicenseTextModal, ConfirmModal } from 'components/modals';
import { Divider } from 'components/divider';
import { RaidixLicense, EraLicense, LicenseSelectionButton, LicenseAddButton } from './license';
import { getOriginalLicenseIds } from './helpers';

export default class LicenseForm extends PureComponent {
  constructor(props) {
    super(props);

    if (props.licensesOrderIm) {
      const licenses = props.licensesOriginalOrderIm || [];

      const licenseTypes = props.licensesOrderIm.map((licenseOrder) => {
        const originalLicenseId = licenseOrder.get('originalLicenseId');

        return {
          licenseId: licenseOrder.get('id'),
          type: originalLicenseId ?
            licenseCreateTypes.CHANGE :
            licenseCreateTypes.NEW,
          originalLicenseData: originalLicenseId ?
            licenses.find(license => license.get('id') === originalLicenseId) :
            null,
        };
      });

      this.props.licensesListComponentAddDelta(licenseTypes);
    }

    this.validate = this.validate.bind(this);
    this.onAddLicense = this.onAddLicense.bind(this);
    this.onChangeLicense = this.onChangeLicense.bind(this);
    this.onRemoveLicense = this.onRemoveLicense.bind(this);
    this.onGenerateLicense = this.onGenerateLicense.bind(this);
    this.onSelectLicense = this.onSelectLicense.bind(this);
  }

  componentWillUnmount() {
    this.props.licensesListComponentResetDelta();
  }

  onAddLicense() {
    this.props.licensesListComponentAddDelta([{ type: licenseCreateTypes.NEW }]);
  }

  onChangeLicense() {
    this.props.modalComponentShowDelta('selectLicense');
  }

  onSelectLicense({ item }) {
    this.props.licensesListComponentAddDelta([{
      type: licenseCreateTypes.CHANGE,
      originalLicenseData: item,
    }]);

    const originalLicenseIds = getOriginalLicenseIds(this.props.licensesListComponentIm);
    this.props.licensesDataForTableGetSignal({ isUnmodified: 1, originalLicenseIds });
  }

  onRemoveLicense(id) {
    this.props.licensesListComponentDeleteDelta(id);

    const originalLicenseIds = getOriginalLicenseIds(this.props.licensesListComponentIm);
    this.props.licensesDataForTableGetSignal({ isUnmodified: 1, originalLicenseIds });
  }

  onGenerateLicense(licenseId, data) {
    this.props.formComponentSubmitWrapperSignal({
      submitSignal: ({ force = false }) => this.props.generatorDataGenerateSignal({
        licenseId,
        orderId: this.props.orderId,
        data: {
          ...data,
          force,
        },
      }),
    });
  }

  get value() {
    // Запрашиваем данные у каждой лицензии
    return this.props.licensesListComponentIm.get('items').map(
      license => this.getLicense(license.get('id')).value,
    );
  }

  getLicense(license) {
    return this[`license_${license}_ref`];
  }

  getLicenseComponent(productType) {
    switch (productType) {
      case productTypes.RAIDIX:
        return RaidixLicense;
      case productTypes.ERA:
        return EraLicense;
      default:
        return null;
    }
  }

  updateLicenseData() {
    const originalLicenseIds = getOriginalLicenseIds(this.props.licensesListComponentIm);

    this.props.licensesDataForTableGetSignal({ isUnmodified: 1, originalLicenseIds });
  }

  validate() {
    let validate = true;

    // Запускаем валидацию для каждой лицензии
    this.props.licensesListComponentIm.get('items').forEach((license) => {
      const validateLicense = this.getLicense(license.get('id')).validate();

      if (validateLicense === false) {
        validate = false;
      }
    });

    return validate;
  }

  render() {
    const {
      isEdit,
      disabled,
      orderType,
      orderId,
      orderStatus,
      orderCurrentStatus,
      productType,
      functionalitiesDataIm,
      licensesListComponentIm,
      licenseSubtypesDataIm,
      formComponentIm,
      licensesDataIm,
      modalComponentShowDelta,
      currentUserRole,
      currentUserLicenseSubtypesDataIm,
      productVersionsDataIm,
      eraLicensesDataForTableGetSignal,
      licensesDataForTableGetSignal,
      eraLicensesDataIm,
      licensesOrderIm,
      eraLicensesDataDeactivateUpdateSignal,
      licensesDataDeactivateUpdateSignal,
    } = this.props;

    const isEra = productType === productTypes.ERA;

    // Собираем id оригинальных лицензий, что бы в последствии
    // они есть в
    // исключить их из списка выбираемых для изменения
    const originalLicenseIds = getOriginalLicenseIds(licensesListComponentIm);
    const onDeactivateLicense = isEra
      ? eraLicensesDataDeactivateUpdateSignal
      : licensesDataDeactivateUpdateSignal;

    const licenses = licensesListComponentIm.get('items').map((license) => {
      const id = license.get('id');
      const licenseId = license.get('licenseId');
      const originalLicenseData = license.get('originalLicenseData');
      const licenseOrderIm = licenseId ?
        licensesOrderIm.find(item => item.get('id') === licenseId) : false;
      let licenseDisabled = disabled;
      let hideRemoveButton = disabled;

      // При изменении аппаратного ключа лицензии
      // для редактирования доступен только он
      if (orderType === orderTypes.HWKEY_CHANGE) {
        licenseDisabled = true;
        hideRemoveButton = false;
      }

      const LicenseComponent = this.getLicenseComponent(productType);

      return (
        <LicenseComponent
          id={id}
          key={id}
          isEdit={isEdit}
          currentUserRole={currentUserRole}
          disabled={licenseDisabled}
          hideRemoveButton={hideRemoveButton}
          type={license.get('type')}
          originalLicenseData={originalLicenseData}
          orderType={orderType}
          orderId={orderId}
          orderStatus={orderStatus}
          orderCurrentStatus={orderCurrentStatus}
          licenseOrderIm={licenseOrderIm}
          licenseSubtypesDataIm={licenseSubtypesDataIm}
          functionalitiesDataIm={functionalitiesDataIm}
          formComponentIm={formComponentIm}
          onRemoveLicense={this.onRemoveLicense}
          onGenerateLicense={this.onGenerateLicense}
          onDeactivateLicense={onDeactivateLicense}
          ref={(ref) => { this[`license_${id}_ref`] = ref; }}
          modalComponentShowDelta={modalComponentShowDelta}
          currentUserLicenseSubtypesDataIm={currentUserLicenseSubtypesDataIm}
          productVersionsDataIm={productVersionsDataIm}
        />
      );
    });


    const updateLicensesDataSignal = isEra
      ? eraLicensesDataForTableGetSignal
      : licensesDataForTableGetSignal;

    const availableLicenses = isEra
      ? eraLicensesDataIm
      : licensesDataIm;

    return (
      <Fragment>
        {licenses}

        {
          disabled !== true ? (
            <div className="form__row form__row_2">
              <div className="controls-group controls-group_form">
                <LicenseAddButton
                  orderType={orderType}
                  onAddLicense={this.onAddLicense}
                />
                <LicenseSelectionButton
                  orderType={orderType}
                  licensesDataIm={availableLicenses}
                  updateLicensesDataSignal={updateLicensesDataSignal}
                  productType={productType}
                  functionalitiesDataIm={functionalitiesDataIm}
                  onChangeLicense={this.onChangeLicense}
                  onSelectLicense={this.onSelectLicense}
                  originalLicenseIds={originalLicenseIds}
                />
              </div>
            </div>
          ) : null
        }

        <Divider />
        <Modal modalId="licenseText">
          <LicenseTextModal />
        </Modal>
        <Modal modalId="confirm">
          <ConfirmModal />
        </Modal>
      </Fragment>
    );
  }
}
