import React, { PureComponent, Fragment } from 'react';
import i18next from 'i18next';
import moment from 'moment';
import { TableAsync } from 'containers';
import {
  roles,
  orderStatus,
  orderTypes,
  CSV_FILE_NAME_DATE_FORMAT,
} from 'constants';
import { orderTypeTranslations, orderStatusTranslations } from 'shared/constants';
import { List } from 'immutable';
import { OrderToolbar } from 'components/toolbars';
import {
  OrderStatusCell,
  DateCell,
  PriceCell,
  LinkCell,
} from 'components/table/cell';

export default class OrdersPage extends PureComponent {
  constructor(props) {
    super(props);

    this.countries = props.countriesDataIm.get('data');
    this.partners = List();
    this.managers = List();
    this.status = [];
    this.types = [];

    const users = props.usersDataIm.get('data');

    users.forEach((user) => {
      const userRole = user.get('role');

      if (userRole === roles.PARTNER) {
        this.partners = this.partners.push(user);
      }

      if (userRole === roles.MANAGER) {
        this.managers = this.managers.push(user);
      }
    });

    this.status = Object.entries(orderStatus).map(([statusName, statusValue]) => ({
      label: i18next.t(orderStatusTranslations[statusName]),
      value: statusValue,
    }));

    this.types = Object.entries(orderTypes).map(([typeName, typeValue]) => ({
      label: i18next.t(orderTypeTranslations[typeName]),
      value: typeValue,
    }));

    this.onExportToFile = this.onExportToFile.bind(this);
  }

  getCountry(model) {
    const countryId = model.get('countryId');
    const country = this.countries.find((country) => country.get('id') === countryId);

    return country ? i18next.t(`countries.${country.get('name')}`) : '-';
  }

  onExportToFile() {
    const { productType, ordersCsvDataGetSignal } = this.props;

    this.ordersTableRef
      .getWrappedInstance()
      .exportToFile({
        productType,
        dataGetSignal: ordersCsvDataGetSignal,
        fileName: `${productType.toLowerCase()}_orders_${moment().format(CSV_FILE_NAME_DATE_FORMAT)}`,
      });
  }

  render() {
    const {
      ordersDataIm,
      authDataIm,
      tableId,
      hideAddButton,
      productType,
      pagination,
      ordersDataForTableGetSignal,
    } = this.props;

    const cells = [
      {
        id: 'uid',
        // TODO: Передавать ссылку не в getValue
        getValue: model => ({
          text: model.get('uid'),
          link: `/orders/${model.get('id')}`,
        }),
        className: 'table__cell_2',
        name: i18next.t('id'),
        sort: {
          type: 'arithmetic',
          field: 'uid',
        },
        component: LinkCell,
      },
      {
        id: 'status',
        getValue: model => model.get('status'),
        className: 'table__cell_3',
        name: i18next.t('status'),
        sort: {
          type: 'alphabetic',
          field: 'status',
        },
        component: OrderStatusCell,
      },
      {
        id: 'status_date',
        getValue: model => model.get('statusDate'),
        className: 'table__cell_4',
        name: i18next.t('date_changed_status'),
        sort: {
          type: 'arithmetic',
          field: 'statusDate',
        },
        component: DateCell,
      },
      {
        id: 'partner',
        getValue: model => model.get('partnerName'),
        className: 'table__cell_3',
        name: i18next.t('partner'),
        sort: {
          type: 'alphabetic',
          field: 'partnerName',
        },
        allowedToShowFor: [roles.ADMIN, roles.MANAGER, roles.ENGINEER, roles.ACCOUNTANT],
      },
      {
        id: 'price',
        getValue: model => ({
          price: model.get('price'),
          currency: model.get('currency'),
        }),
        className: 'table__cell_4',
        name: i18next.t('price'),
        component: PriceCell,
      },
      {
        id: 'invoice',
        getValue: model => model.get('invoice'),
        className: 'table__cell_5',
        name: i18next.t('invoice'),
        sort: {
          type: 'alphabetic',
          field: 'invoice',
        },
      },
      {
        id: 'countryId',
        getValue: model => this.getCountry(model),
        className: 'table__cell_3',
        name: i18next.t('country'),
        sort: {
          type: 'alphabetic',
          field: 'countryId',
        },
      },
    ];

    const filterFields = [
      {
        type: 'multiSelect',
        key: 'partnerId',
        name: i18next.t('partner'),
        getValue: item => item.get('id'),
        getText: item => item.get('name'),
        items: this.partners,
        allowedToShowFor: [roles.ADMIN, roles.MANAGER, roles.ENGINEER, roles.ACCOUNTANT],
        sort: true,
      },
      {
        type: 'multiSelect',
        key: 'managerId',
        name: i18next.t('manager'),
        getValue: item => item.get('id'),
        getText: item => item.get('name'),
        items: this.managers,
        allowedToShowFor: [roles.ADMIN, roles.MANAGER, roles.ENGINEER, roles.ACCOUNTANT],
        sort: true,
      },
      {
        type: 'multiSelect',
        key: 'countryId',
        name: i18next.t('countries_label'),
        getValue: item => item.get('id'),
        getText: item => i18next.t(`countries.${item.get('name')}`),
        items: this.countries,
        allowedToShowFor: [roles.ADMIN, roles.MANAGER, roles.ENGINEER, roles.ACCOUNTANT],
        sort: true,
      },
      {
        type: 'orderStatus',
        key: 'status',
        name: i18next.t('status'),
        items: this.status,
      },
      {
        type: 'multiSelect',
        key: 'type',
        name: i18next.t('type'),
        items: this.types,
      },
      {
        type: 'dateInterval',
        key: 'creationDate',
        name: i18next.t('creation_date'),
      },
      // LIC-287: Убрать фильтр "Purchase order" из меню фильтрации
      // {
      //   type: 'text',
      //   key: 'purchaseOrder',
      //   name: i18next.t('purchase_order'),
      // },
      {
        type: 'text',
        key: 'invoice',
        name: i18next.t('invoice'),
      },
      {
        type: 'text',
        key: 'hardwareKey',
        name: i18next.t('hardware_key'),
      },
    ];

    const quickFilterFields = [
      {
        key: 'pending',
        name: i18next.t('pending'),
        values: {
          status: orderStatus.PENDING,
          type: orderTypes.COMMERCIAL,
        },
        allowedToShowFor: [roles.ACCOUNTANT],
      },
      {
        key: 'documents_sent',
        name: i18next.t('documents_sent'),
        values: {
          status: orderStatus.DOCUMENTS_SENT,
          type: orderTypes.COMMERCIAL,
        },
        allowedToShowFor: [roles.ACCOUNTANT],
      },
      {
        key: 'documents_sent',
        name: i18next.t('documents_sent'),
        values: {
          status: orderStatus.DOCUMENTS_SENT,
          type: orderTypes.HWKEY_CHANGE,
        },
        allowedToShowFor: [roles.ENGINEER],
      },
      {
        key: 'cancelled',
        name: i18next.t('not_canceled'),
        values: {
          status: [
            orderStatus.PENDING,
            orderStatus.DOCUMENTS_SENT,
            orderStatus.GENERATION_READY,
          ],
        },
      },
    ];

    return (
      <Fragment>
        <OrderToolbar
          productType={productType}
          tableId={tableId}
          filterFields={filterFields}
          quickFilterFields={quickFilterFields}
          authDataIm={authDataIm}
          hideAddButton={hideAddButton}
          onExportToFile={this.onExportToFile}
          tableGetSignal={ordersDataForTableGetSignal}
        />
        <div className="content__body">
          <TableAsync
            id={tableId}
            items={ordersDataIm}
            cells={cells}
            filterFields={filterFields}
            ref={(ref) => { this.ordersTableRef = ref; }}
            pagination={pagination}
            tableGetSignal={ordersDataForTableGetSignal}
          />
        </div>
      </Fragment>
    );
  }
}
