import React, { PureComponent, Fragment } from 'react';
import i18next from 'i18next';
import { Form } from 'containers';
import AnalyticsForm from 'components/form/analytics';
import LicensesChart from '../../charts/licenses-chart';
import FeaturesChart from '../../charts/features-chart';

export default class Analytics extends PureComponent {
  render() {
    const {
      authDataIm,
      analyticsDataIm,
      analyticsDataAddSignal,
      countriesDataIm,
      licensesCsvDataGetSignal,
      match,
    } = this.props;

    if (!match) {
      return null;
    }

    const currentLanguage = authDataIm.getIn(['data', 'language']);
    const licenses = analyticsDataIm.getIn(['data', 'licenses']);
    const features = analyticsDataIm.getIn(['data', 'features']);
    const countries = countriesDataIm.getIn(['data']);

    const licensesArray = licenses ? licenses.toJS() : [];
    const featuresArray = features ? features.toJS() : [];
    const countriesArray = countries ? countries.toJS() : [];

    const hasLicensesChart = licenses && licensesArray.length > 0;
    const isEra = licensesArray.every(lic => lic.counterDC === 0) && featuresArray.length === 0;

    return (
      <Fragment>
        <div className="content__head">
          <h1 className="content__title">
            {i18next.t('analytics')}
          </h1>
        </div>

        <div className="content__body content__body_column">
          <Form id="analitycs-form">
            <AnalyticsForm
              submitSignal={analyticsDataAddSignal}
              saveSignal={licensesCsvDataGetSignal}
              locale={currentLanguage}
              countries={countriesArray}
            />
          </Form>

          <div className="form-line" style={{ margin: '20px 0' }} />

          <div className="content__block">
            { hasLicensesChart ?
              <LicensesChart licenses={licensesArray} isEra={isEra} /> :
              <div className="empty-table empty-table__title empty-table__title-p50">
                {i18next.t('no_data_here')}
              </div>
            }

            { hasLicensesChart && !isEra && <FeaturesChart features={featuresArray} /> }
          </div>
        </div>
      </Fragment>
    );
  }
}
