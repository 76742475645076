import React, { PureComponent } from 'react';
import classnames from 'classnames';
import PopupMenu from 'components/popup-menu';
import _isFunction from 'lodash/isFunction';
import MoreIcon from 'img/more.svg';
import TableCell from './cell';
import ActionButton from './action-button';

// Если в значении undefined, то поставим тире
const getCellValue = value => (value === undefined ? '\u2014' : value);

export default class TableRow extends PureComponent {
  constructor(props) {
    super(props);

    this.onClickRow = this.onClickRow.bind(this);
  }

  onClickRow() {
    const { item, cells } = this.props;
    const id = item.get('id');

    this.props.onClickRow({ id, item, cells });
  }

  render() {
    const { item, cells, disabled, getClassName, onClickRow, actionButtons = [] } = this.props;
    const interactiveRow = _isFunction(onClickRow);

    let className = classnames(
      'table__row',
      {
        table__row_disabled: disabled,
        table__row_interactive: interactiveRow,
      }
    );

    if (getClassName) {
      className += ` ${getClassName(item)}`;
    }

    const cellCount = cells.length - 1;
    let showActionButtonsCount = 0;

    const popUpMenu = (
      <div className="popup-menu">
        {
          actionButtons.map((button) => {
            const label = _isFunction(button.label) ? button.label(item) : button.label;
            const isShow = _isFunction(button.isShow) ? button.isShow(item) : true;

            if (isShow) {
              showActionButtonsCount += 1;

              return (
                <ActionButton
                  label={label}
                  key={label}
                  className={button.className}
                  onClick={button.onClick}
                  item={item}
                />
              );
            }

            return null;
          })
        }
      </div>
    );

    return (
      <div className={className} onClick={interactiveRow ? this.onClickRow : null}>
        {cells.map((cell, key) => {
          const CellComponent = cell.component || TableCell;
          const componentProps = cell.componentProps || {};
          const valueRaw = cell.getValue(item);
          const value = getCellValue(valueRaw);

          const cellClassName = classnames(
            'table__cell',
            cell.className,
            { 'table__cell_has-action-button': cellCount === key },
          );

          return (
            <CellComponent
              id={item.get('id')}
              key={cell.name + cell.className}
              className={cellClassName}
              value={value}
              valueRaw={valueRaw}
              {...componentProps}
            />
          );
        })}
        {
          actionButtons.length > 0 ? (
            <div className="table__cell table__cell_action-button">
              {
                showActionButtonsCount > 0 ? (
                  <PopupMenu
                    content={popUpMenu}
                  >
                    <button className="button button_cell-action">
                      <MoreIcon width="12" height="12" />
                    </button>
                  </PopupMenu>
                ) : null
              }
            </div>
          ) : null
        }
      </div>
    );
  }
}

