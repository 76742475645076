import React, { PureComponent } from 'react';
import { Form } from 'containers';
import OrderForm from 'components/form/order';

export default class EditOrderBody extends PureComponent {
  render() {
    const {
      currentUserLicenseSubtypesDataIm,
      eraLicensesDataForTableGetSignal,
      eraLicensesDataIm,
      countriesDataIm,
      eraLicensesForOrderGetSignal,
      functionalitiesDataGetSignal,
      functionalitiesDataIm,
      generatorDataGenerateSignal,
      licensesDataDeactivateUpdateSignal,
      licensesDataForTableGetSignal,
      licensesDataIm,
      licensesForOrderGetSignal,
      licensesListComponentAddDelta,
      licensesListComponentDeleteDelta,
      licensesListComponentIm,
      licensesListComponentResetDelta,
      licenseSubtypesDataIm,
      modalComponentShowDelta,
      orderIm,
      ordersDataUpdateSignal,
      productVersionsDataIm,
      usersDataIm,
      licenseSubtypesDataGetSignal,
      eraLicensesDataDeactivateUpdateSignal,
    } = this.props;

    return (
      <div className="content__body">
        <Form id="add-order">
          <OrderForm
            isEdit
            orderIm={orderIm}
            countriesDataIm={countriesDataIm}
            licensesDataIm={licensesDataIm}
            eraLicensesDataIm={eraLicensesDataIm}
            licensesListComponentIm={licensesListComponentIm}
            licenseSubtypesDataIm={licenseSubtypesDataIm}
            currentUserLicenseSubtypesDataIm={currentUserLicenseSubtypesDataIm}
            functionalitiesDataIm={functionalitiesDataIm}
            productVersionsDataIm={productVersionsDataIm}
            usersDataIm={usersDataIm}
            licensesListComponentAddDelta={licensesListComponentAddDelta}
            licensesListComponentDeleteDelta={licensesListComponentDeleteDelta}
            licensesListComponentResetDelta={licensesListComponentResetDelta}
            modalComponentShowDelta={modalComponentShowDelta}
            submitSignal={ordersDataUpdateSignal}
            functionalitiesDataGetSignal={functionalitiesDataGetSignal}
            generatorDataGenerateSignal={generatorDataGenerateSignal}
            eraLicensesForOrderGetSignal={eraLicensesForOrderGetSignal}
            licensesForOrderGetSignal={licensesForOrderGetSignal}
            eraLicensesDataForTableGetSignal={eraLicensesDataForTableGetSignal}
            licensesDataForTableGetSignal={licensesDataForTableGetSignal}
            licenseSubtypesDataGetSignal={licenseSubtypesDataGetSignal}
            eraLicensesDataDeactivateUpdateSignal={eraLicensesDataDeactivateUpdateSignal}
            licensesDataDeactivateUpdateSignal={licensesDataDeactivateUpdateSignal}
          />
        </Form>
      </div>
    );
  }
}
