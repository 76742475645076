import { connect } from 'react-redux';
import Form from 'components/form';
import { actions as formComponentActions } from 'ducks/components/form';

const mapDispatchToProps = Object.assign({}, formComponentActions);

function mapStateToProps(state, ownProps) {
  return {
    authDataIm: state.data.authDataIm,
    formComponentIm: state.components.formComponentIm.get(ownProps.id),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
