import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { TableFilter } from 'containers';
import Tooltip from 'components/tooltip';
import ExpandMoreIcon from 'img/expand-more.svg';

export default class QuickFilterButton extends PureComponent {
  constructor(props) {
    super(props);

    this.onSetFilter = this.onSetFilter.bind(this);
  }

  onSetFilter(event) {
    event.preventDefault();

    const {
      id,
      values,
      buttonId,
      tableComponentIm,
      tableComponentChangeQuickFiltersDelta,
      tableComponentResetFiltersDelta,
      tableComponentChangeQuickFiltersDeltaAsync,
      tableComponentResetFiltersDeltaAsync,
      filterSignal,
    } = this.props;

    // Если быстрый фильтр уже применён, при повторном нажатие отключаем его
    const isNeedReset = tableComponentIm && tableComponentIm.get('quickFilter') === buttonId;

    // Ассинхронная фильтрация
    if (filterSignal) {
    // Если быстрый фильтр уже применён, при повторном нажатие отключаем его
      if (isNeedReset) {
        tableComponentResetFiltersDeltaAsync(filterSignal, { id });
      } else {
        tableComponentChangeQuickFiltersDeltaAsync(filterSignal, { id, filters: values, name: buttonId });
      }
      return;
    }

    if (isNeedReset) {
      tableComponentResetFiltersDelta(id);
    } else {
      // Применяем быстрый фильтр
      tableComponentChangeQuickFiltersDelta(id, values, buttonId);
    }
  }

  render() {
    const {
      tableComponentIm,
      name,
      buttonId,
      fields,
      values,
      title,
      id,
      filterSignal,
    } = this.props;
    const select = tableComponentIm && tableComponentIm.get('quickFilter') === buttonId;
    let content;

    if (fields && fields.length > 0) {
      content = (
        <TableFilter
          id={id}
          filterFields={fields}
          quickFilterName={buttonId}
          defaultValue={values}
          filterSignal={filterSignal}
          isQuickFilter
        />
      );
    }

    return (
      <div className="controls-group__control">
        <div className="button-tooltip">
          <button
            className={classnames('button', {
              button_action: select,
              button_split: fields && fields.length > 0,
            })}
            onClick={this.onSetFilter}
            data-tip={title}
            data-for="quickFilter"
          >
            {name}
          </button>
          {
            fields && fields.length > 0 ? (
              <Tooltip
                content={content}
                classNameButtonShow="button_show-tooltip-toggle"
              >
                <button
                  className={classnames('button', 'button_tooltip-toggle', 'button_icon', {
                    button_action: select,
                  })}
                >
                  <span className="button__icon button__icon_no-margin">
                    <ExpandMoreIcon
                      width="24"
                      height="24"
                    />
                  </span>
                </button>
              </Tooltip>
            ) : null
          }
        </div>
      </div>
    );
  }
}
