import i18next from 'i18next';
import moment from 'moment';
import { roles, orderStatus } from 'shared/constants';

// Функции для формирования quickFilterFields

export const getNoSupportFilter = formatCurrentDay => ({
  key: 'no_support',
  name: i18next.t('no_support'),
  title: i18next.t('no_support_title'),
  values: {
    supportExpirationDate: [
      null,
      formatCurrentDay,
      true, // Лицензии с незаданным периодом поддержки
    ],
  },
  allowedToShowFor: [roles.MANAGER],
});

export const getExpiringSupportFilter = (currentDay) => {
  const formatCurrentDay = currentDay.format('X');

  return {
    key: 'expiring_support',
    name: i18next.t('expiring_support'),
    fields: [
      {
        type: 'dateInterval',
        key: 'supportExpirationDate',
        name: i18next.t('support_end_date'),
      },
      {
        type: 'checkbox',
        // Ключ используется на сервере
        key: 'oneManager',
        name: i18next.t('only_my_licenses'),
      },
    ],
    values: {
      supportExpirationDate: [
        formatCurrentDay,
        currentDay
          .add({ days: 30, hours: 23, minutes: 59, seconds: 59 })
          .format('X'),
      ],
      managerId: true,
    },
    allowedToShowFor: [roles.MANAGER],
  };
};

export const getIssueDateFilter = () => {
  // Начало текущего квартала
  const startQuartedDate = moment().startOf('quarter').format('X');

  return {
    key: 'issue_date',
    name: i18next.t('issue_date'),
    fields: [
      {
        type: 'dateInterval',
        key: 'generatedDate',
        name: i18next.t('issue_date'),
      },
    ],
    values: {
      generatedDate: [
        startQuartedDate,
        undefined,
      ],
    },
    allowedToShowFor: [roles.ACCOUNTANT],
  };
};

export const getNotCanceledFilter = () => ({
  key: 'orderStatus',
  name: i18next.t('not_canceled'),
  values: {
    orderStatus: [
      orderStatus.PENDING,
      orderStatus.DOCUMENTS_SENT,
      orderStatus.GENERATION_READY,
    ],
  },
});
