
export function getTableComponentImObject(tableComponentIm, options = {}) {
  const { id, ...params } = options;

  const filters = tableComponentIm && tableComponentIm.getIn([id, 'filters']);
  const sort = tableComponentIm && tableComponentIm.getIn([id, 'sort']);
  const pageSize = tableComponentIm && tableComponentIm.getIn([id, 'itemsPerPage']);
  const currentPage = tableComponentIm && tableComponentIm.getIn([id, 'page']);

  return {
    filters: filters && filters.toJS(),
    sort: sort && sort.toJS(),
    pageSize,
    page: currentPage - 1,
    ...params,
  };
}
