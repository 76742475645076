import React from 'react';
import Tooltip from 'components/tooltip';
import i18next from 'i18next';
import { languages } from 'constants';
import UserIcon from 'img/user.svg';
import Language from './language';

export default function UserMenu({ authDataIm, authDataSetLanguageDelta, authDataLogoutSignal }) {
  const currentLanguage = authDataIm.getIn(['data', 'language']);
  const login = authDataIm.getIn(['data', 'login']);
  const popUpMenu = (
    <div className="user-menu__wrapper">
      <div className="user-menu__login">{login}
        <a href="/personalisation" className="user-menu__edit">
          {i18next.t('edit')}
        </a>
      </div>
      <div className="user-menu__languages">
        <span className="user-menu__language-label">{i18next.t('language')}</span>
        <div className="user-menu__languages-btns">
          {
            Object.keys(languages).map(language => (
              <Language
                key={language}
                languageCode={language}
                currentLanguage={currentLanguage}
                setLanguageDelta={authDataSetLanguageDelta}
              />
            ))
          }
        </div>
      </div>
      <a
        href="#log-out"
        className="user-menu__item"
        onClick={authDataLogoutSignal}
      >
        {i18next.t('log_out')}
      </a>
    </div>
  );

  return (
    <div className="user-menu">
      <Tooltip
        className="tooltip__content_user-menu"
        content={popUpMenu}
      >
        <button className="user-menu__button">
          <div className="user-menu__icon">
            <UserIcon width="26" height="26" />
          </div>
        </button>
      </Tooltip>
    </div>
  );
}
