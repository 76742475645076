import React, { Fragment } from 'react';
import i18next from 'i18next';
import { Modal } from 'containers';
import { SelectLicenseModal } from 'components/modals';
import { orderTypes } from 'shared/constants';
import FetchData from 'components/fetch-data';

export const LicenseSelectionButton = ({
  licensesDataIm,
  updateLicensesDataSignal,
  productType,
  functionalitiesDataIm,
  onChangeLicense,
  onSelectLicense,
  originalLicenseIds,
  orderType,
}) => {
  const isCommercial = orderType === orderTypes.COMMERCIAL;
  const isHWKeyChange = orderType === orderTypes.HWKEY_CHANGE;

  const changeLicenseButtonText = isHWKeyChange ?
    i18next.t('select_license') :
    i18next.t('change_license');

  const pagination = licensesDataIm.get('totalCount');
  const allAvailableLicensesCount = licensesDataIm.get('allLicensesCount');
  const items = licensesDataIm.get('data');

  const showChangeLicenseButton = allAvailableLicensesCount && allAvailableLicensesCount > 0 &&
    (isCommercial || isHWKeyChange);

  return (
    <FetchData
      updateLicensesDataSignal={updateLicensesDataSignal}
      fetchActionNames={['updateLicensesDataSignal']}
      fetchAttributesByActionNames={{
        updateLicensesDataSignal: { isUnmodified: 1, originalLicenseIds },
      }}
    >
      <Fragment>
        {showChangeLicenseButton ? (
          <div className="controls-group__control">
            <input
              type="button"
              className="button"
              value={changeLicenseButtonText}
              onClick={onChangeLicense}
            />
          </div>
        ) : null }

        <Modal modalId="selectLicense" wide>
          <SelectLicenseModal
            updateLicensesDataSignal={updateLicensesDataSignal}
            productType={productType}
            functionalitiesDataIm={functionalitiesDataIm}
            onSelectLicense={onSelectLicense}
            pagination={pagination}
            items={items}
            signalAttributes={{ isUnmodified: 1, originalLicenseIds }}
          />
        </Modal>
      </Fragment>
    </FetchData>
  );
};
