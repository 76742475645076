import React, { useState, useEffect } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import i18next from 'i18next';
import Switch from 'react-switch';

ChartJS.register(
  BarElement,
);

const getDatasets = (features) => {
  const labelsWithZoros = [];
  const countersWithZeros = [];
  const labelsNoZoros = [];
  const countersNoZeros = [];

  features.forEach((feature) => {
    labelsWithZoros.push(feature.label);
    countersWithZeros.push(feature.counter);

    if (feature.counter !== 0) {
      labelsNoZoros.push(feature.label);
      countersNoZeros.push(feature.counter);
    }
  });

  const datasetWithZeros = [{
    data: countersWithZeros,
    backgroundColor: 'rgba(193, 40, 128, 0.8)',
  }];

  const datasetNoZeros = [{
    data: countersNoZeros,
    backgroundColor: 'rgb(71, 51, 135, 0.8)',
  }];

  const data = {
    withZeros: { labels: labelsWithZoros, datasets: datasetWithZeros },
    noZeros: { labels: labelsNoZoros, datasets: datasetNoZeros },
  };

  return data;
};

const FeaturesChart = (props) => {
  const { features } = props;
  const data = getDatasets(features);
  const [showZeros, setShowZeros] = useState(true);
  const [datasets, setDatasets] = useState(data.withZeros);

  useEffect(() => {
    setDatasets(data[showZeros ? 'withZeros' : 'noZeros']);
  }, [features]);

  const onChange = (checked) => {
    setShowZeros(checked);
    if (checked) {
      setDatasets(data.withZeros);
      return;
    }
    setDatasets(data.noZeros);
  };

  return (
    <div>
      <h2>
        {i18next.t('features_analytics')}
      </h2>

      <div className="form__row_gap-15" style={{ marginBottom: '15px' }}>
        <label className="form__label form__label_row form__field_mr-0">
          {i18next.t('show_zero_values')}:
        </label>
        <Switch
          onChange={onChange}
          checked={showZeros}
          onColor="#e094c0"
          onHandleColor="#c12880"
          handleDiameter={20}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={14}
          width={34}
          className="react-switch"
          id="material-switch"
        />
      </div>

      <div className="content__body">
        <div style={{ width: '800px' }}>
          <Bar
            plugins={[ChartDataLabels]}
            options={{
              legend: {
                display: false,
              },
              responsive: true,
              indexAxis: 'y',
              interaction: {
                intersect: false,
              },
              plugins: {
                tooltip: {
                  enabled: false,
                },
                datalabels: {
                  anchor: 'start',
                  color: '#333',
                  clamp: true,
                  align: 'end'
                }
              }
            }}
            data={datasets}
          />
        </div>
      </div>
    </div>
  );
};

export default FeaturesChart;
