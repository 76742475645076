import { Map } from 'immutable';
import { applicationName } from 'config';
import { getTableComponentImObject } from 'helpers';

/*
* Constants
* */
const MODULE_NAME = 'table-component';

/*
* Actions Types
* */
const RESET = `${applicationName}/${MODULE_NAME}/RESET`;
const SORT_CHANGE = `${applicationName}/${MODULE_NAME}/SORT_CHANGE`;
const CHANGE_PAGE = `${applicationName}/${MODULE_NAME}/CHANGE_PAGE`;
const CHANGE_ITEMS_PER_PAGE = `${applicationName}/${MODULE_NAME}/CHANGE_ITEMS_PER_PAGE`;
const CHANGE_FILTERS = `${applicationName}/${MODULE_NAME}/CHANGE_FILTERS`;
const CHANGE_LOADING = `${applicationName}/${MODULE_NAME}/CHANGE_LOADING`;
const CHANGE_QUICK_FILTERS = `${applicationName}/${MODULE_NAME}/CHANGE_QUICK_FILTERS`;
const RESET_FILTERS = `${applicationName}/${MODULE_NAME}/RESET_FILTERS`;


/*
* Reducer
* */

const initialState = Map();

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET: {
      return state.delete(action.payload.id);
    }

    case SORT_CHANGE: {
      return state.mergeDeep({
        [action.payload.id]: {
          page: 1,
          sort: action.payload.sort,
        },
      });
    }

    case CHANGE_PAGE: {
      return state.mergeDeep({
        [action.payload.id]: {
          page: action.payload.page,
        },
      });
    }

    case CHANGE_ITEMS_PER_PAGE: {
      return state.mergeDeep({
        [action.payload.id]: {
          page: 1,
          itemsPerPage: action.payload.itemsPerPage,
        },
      });
    }

    case CHANGE_FILTERS:
    case CHANGE_QUICK_FILTERS: {
      const isQuickFilter = action.type === CHANGE_QUICK_FILTERS;

      // Не даём установить для быстрого фильтра пустого значения
      if (isQuickFilter && Object.keys(action.payload.filters).length === 0) {
        return state;
      }

      const tableFilter = Map(state.get(action.payload.id));
      const newTableFilter = tableFilter.withMutations((mutable) => {
        mutable
          .set('filters', Map(action.payload.filters))
          .set('quickFilter', isQuickFilter ? action.payload.name : false)
          .set('page', 1)
          .set('itemsPerPage', 10);
      });

      return state.set(action.payload.id, newTableFilter);
    }

    case RESET_FILTERS: {
      return state.mergeDeep({
        [action.payload.id]: {
          page: 1,
          quickFilter: false,
          filters: false,
        },
      });
    }

    case CHANGE_LOADING: {
      return state.mergeDeep({
        [action.payload.id]: {
          loading: action.payload.loading,
        },
      });
    }

    default: {
      return state;
    }
  }
}

/*
* Actions
* */

const tableComponentResetDelta = id => ({ type: RESET, payload: { id } });
const tableComponentSortChangeDelta = (id, sort) => ({ type: SORT_CHANGE, payload: { id, sort } });
const tableComponentChangePageDelta = (id, page) => ({ type: CHANGE_PAGE, payload: { id, page } });
const tableComponentChangeItemsPerPageDelta = (id, itemsPerPage) => ({
  type: CHANGE_ITEMS_PER_PAGE,
  payload: { id, itemsPerPage },
});
const tableComponentChangeFiltersDelta = (id, filters) => ({
  type: CHANGE_FILTERS,
  payload: { id, filters },
});
const tableComponentChangeQuickFiltersDelta = (id, filters, name) => ({
  type: CHANGE_QUICK_FILTERS,
  payload: { id, filters, name },
});
const tableComponentResetFiltersDelta = id => ({ type: RESET_FILTERS, payload: { id } });

const tableComponentLoadingChangeDelta = (id, loading) => ({ type: CHANGE_LOADING, payload: { id, loading } });

/**
 * Async Actions
 */

const tableComponentChangeQuickFiltersDeltaAsync = (signal, options = {}) => {
  const { id, filters, name, signalAttributes = {} } = options;

  return async (dispatch, getState) => {
    try {
      const { tableComponentIm } = getState().components;
      const signalOptions = getTableComponentImObject(tableComponentIm, { id, page: 0, filters });

      dispatch(tableComponentLoadingChangeDelta(id, true));
      await signal({ ...signalOptions, ...signalAttributes });

      dispatch(tableComponentChangeQuickFiltersDelta(id, filters, name));
      dispatch(tableComponentLoadingChangeDelta(id, false));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error in tableComponentChangeQuickFiltersDeltaAsync: ', error);
    }
  };
};

const tableComponentChangePageDeltaAsync = (signal, options = {}) => {
  const { id, page, signalAttributes = {} } = options;

  return async (dispatch, getState) => {
    try {
      const { tableComponentIm } = getState().components;
      const signalOptions = getTableComponentImObject(tableComponentIm, { id, page: page - 1 });

      dispatch(tableComponentLoadingChangeDelta(id, true));
      await signal({ ...signalOptions, ...signalAttributes });

      dispatch(tableComponentChangePageDelta(id, page));
      dispatch(tableComponentLoadingChangeDelta(id, false));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error in tableComponentChangePageDeltaAsync: ', error);
    }
  };
};

const tableComponentChangeItemsPerPageDeltaAsync = (signal, options = {}) => {
  const { id, pageSize, signalAttributes = {} } = options;

  return async (dispatch, getState) => {
    try {
      const { tableComponentIm } = getState().components;
      const signalOptions = getTableComponentImObject(tableComponentIm, { id, pageSize, page: 0 });

      dispatch(tableComponentLoadingChangeDelta(id, true));
      await signal({ ...signalOptions, ...signalAttributes });

      dispatch(tableComponentChangeItemsPerPageDelta(id, pageSize));
      dispatch(tableComponentLoadingChangeDelta(id, false));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error in tableComponentChangeItemsPerPageDeltaAsync: ', error);
    }
  };
};

const tableComponentSortChangeDeltaAsync = (signal, options = {}) => {
  const { id, sort, signalAttributes = {} } = options;

  return async (dispatch, getState) => {
    try {
      const { tableComponentIm } = getState().components;
      const signalOptions = getTableComponentImObject(tableComponentIm, { id, sort, page: 0 });

      dispatch(tableComponentLoadingChangeDelta(id, true));
      await signal({ ...signalOptions, ...signalAttributes });

      dispatch(tableComponentSortChangeDelta(id, sort));
      dispatch(tableComponentLoadingChangeDelta(id, false));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error in tableComponentSortChangeDeltaAsync: ', error);
    }
  };
};

const tableComponentChangeFiltersDeltaAsync = (signal, options = {}) => {
  const { id, filters, signalAttributes = {} } = options;

  return async (dispatch, getState) => {
    try {
      const { tableComponentIm } = getState().components;
      const signalOptions = getTableComponentImObject(tableComponentIm, {
        id,
        filters,
        page: 0,
      });

      dispatch(tableComponentLoadingChangeDelta(id, true));
      await signal({ ...signalOptions, ...signalAttributes });

      dispatch(tableComponentChangeFiltersDelta(id, filters));
      dispatch(tableComponentLoadingChangeDelta(id, false));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error in tableComponentChangeFiltersDeltaAsync: ', error);
    }
  };
};

const tableComponentResetFiltersDeltaAsync = (signal, options = {}) => {
  const { id, signalAttributes = {} } = options;

  return async (dispatch, getState) => {
    try {
      const { tableComponentIm } = getState().components;
      const signalOptions = getTableComponentImObject(tableComponentIm, { id, filters: {}, page: 0 });

      dispatch(tableComponentLoadingChangeDelta(id, true));
      await signal({ ...signalOptions, ...signalAttributes });

      dispatch(tableComponentResetFiltersDelta(id));
      dispatch(tableComponentLoadingChangeDelta(id, false));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error in tableComponentResetFiltersDeltaAsync: ', error);
    }
  };
};

export const actions = {
  tableComponentResetDelta,
  tableComponentSortChangeDelta,
  tableComponentChangePageDelta,
  tableComponentChangeItemsPerPageDelta,
  tableComponentChangeFiltersDelta,
  tableComponentChangeQuickFiltersDelta,
  tableComponentChangeQuickFiltersDeltaAsync,
  tableComponentResetFiltersDelta,
  tableComponentLoadingChangeDelta,
  tableComponentChangePageDeltaAsync,
  tableComponentChangeItemsPerPageDeltaAsync,
  tableComponentSortChangeDeltaAsync,
  tableComponentChangeFiltersDeltaAsync,
  tableComponentResetFiltersDeltaAsync,
};
