/* eslint-disable no-underscore-dangle */
import React, { PureComponent, Fragment } from 'react';
import i18next from 'i18next';
import { TableAsync } from 'containers';
import { roles, productTypes, driveTypes, driveTypeTranslations } from 'shared/constants';
import { booleanSelectList } from 'constants';
import ModalHeader from 'components/modal/modal-header';
import {
  DateCell,
  FunctionalityCell,
  LicenseStatusCell,
  LicenseTypeCell,
  SupportEndDateCell,
  LicenseDriveTypeCell,
  BooleanCell,
} from 'components/table/cell';
import { LicenseToolbar } from 'components/toolbars';

export default class SelectLicenseModal extends PureComponent {
  constructor(props) {
    super(props);
    this._isMounted = false;

    this.state = {
      loading: true,
    };

    this.functionality = props.functionalitiesDataIm.get('data');
    this.booleanSelectList = booleanSelectList.map(
      ({ label, value }) => ({ label: i18next.t(label), value })
    );

    this.driveTypes = Object.entries(driveTypes).map(([typeName, typeValue]) => ({
      label: i18next.t(driveTypeTranslations[typeName]),
      value: typeValue,
    }));

    this.driveTypes.unshift({
      label: i18next.t('all'),
      value: '',
    });

    this.onSelectLicense = this.onSelectLicense.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    this.props.updateLicensesDataSignal(this.props.signalAttributes).then(() => {
      // если компонент еще не размонтирован
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSelectLicense({ item }) {
    this.props.modalComponentHideSignal();
    this.props.onSelectLicense({ item });
  }

  render() {
    const { productType, updateLicensesDataSignal, items, pagination, signalAttributes } = this.props;

    const cells = [
      {
        id: 'id',
        getValue: model => model.get('id'),
        className: 'table__cell_2',
        name: i18next.t('id'),
        sort: {
          type: 'arithmetic',
          field: 'id',
        },
      },
      {
        id: 'type',
        getValue: model => model.get('type'),
        className: 'table__cell_2',
        name: i18next.t('type'),
        sort: {
          type: 'alphabetic',
          field: 'type',
        },
        component: LicenseTypeCell,
      },
      {
        id: 'partner',
        getValue: model => model.get('partnerName'),
        className: 'table__cell_3',
        name: i18next.t('partner'),
        sort: {
          type: 'alphabetic',
          field: 'partnerName',
        },
        allowedToShowFor: [roles.ADMIN, roles.MANAGER, roles.ENGINEER, roles.ACCOUNTANT],
      },
      {
        id: 'status',
        getValue: model => model.get('status'),
        className: 'table__cell_3',
        name: i18next.t('status'),
        sort: {
          type: 'alphabetic',
          field: 'status',
        },
        component: LicenseStatusCell,
      },
      {
        id: 'activation_date',
        getValue: model => model.get('generatedDate'),
        className: 'table__cell_3',
        name: i18next.t('activation_date'),
        sort: {
          type: 'arithmetic',
          field: 'generatedDate',
        },
        component: DateCell,
      },
      {
        id: 'support_end_date',
        getValue: model => model.get('supportExpirationDate'),
        className: 'table__cell_3',
        name: i18next.t('support_end_date'),
        sort: {
          type: 'arithmetic',
          field: 'supportExpirationDate',
        },
        component: SupportEndDateCell,
      },
      {
        id: 'order_id',
        getValue: model => model.get('orderUid'),
        className: 'table__cell_2',
        name: i18next.t('order_id'),
        sort: {
          type: 'arithmetic',
          field: 'orderId',
        },
      },
      {
        id: 'functionality',
        getValue: model => model.get('functionalities'),
        className: 'table__cell_6',
        name: i18next.t('functionality'),
        component: FunctionalityCell,
        componentProps: {
          functionalities: this.functionality,
        },
        isShow: productType === productTypes.RAIDIX,
      },
      {
        id: 'drive_type',
        getValue: model => model.get('driveType'),
        className: 'table__cell_3',
        name: i18next.t('drive_type'),
        sort: {
          type: 'alphabetic',
          field: 'driveType',
        },
        component: LicenseDriveTypeCell,
        isShow: productType === productTypes.ERA,
      },
      {
        id: 'extended_raid',
        getValue: model => model.get('extendedRaid'),
        className: 'table__cell_3',
        name: i18next.t('additional_raid_short'),
        sort: {
          type: 'alphabetic',
          field: 'extendedRaid',
        },
        component: BooleanCell,
        isShow: productType === productTypes.ERA,
      },
    ];

    const filterFields = [
      {
        type: 'text',
        key: 'customer',
        name: i18next.t('customer'),
      },
      {
        type: 'dateInterval',
        key: 'generatedDate',
        name: i18next.t('issue_date'),
      },
      {
        type: 'dateInterval',
        key: 'supportExpirationDate',
        name: i18next.t('support_end_date'),
      },
      {
        type: 'text',
        key: 'hardwareKey',
        name: i18next.t('hardware_key'),
      },
      // LIC-287: Убрать фильтр "Purchase order" из меню фильтрации
      // {
      //   type: 'text',
      //   key: 'purchaseOrder',
      //   name: i18next.t('purchase_order'),
      // },
      {
        type: 'text',
        key: 'id',
        name: i18next.t('id'),
      },
    ];

    if (productType === productTypes.RAIDIX) {
      filterFields.push({
        type: 'multiSelect',
        key: 'functionalities',
        name: i18next.t('features'),
        getValue: item => item.get('id'),
        getText: item => item.get('name'),
        items: this.functionality,
      });
    }

    if (productType === productTypes.ERA) {
      filterFields.push({
        type: 'select',
        key: 'driveType',
        name: i18next.t('drive_type'),
        items: this.driveTypes,
      },
      {
        type: 'select',
        key: 'extendedRaid',
        name: i18next.t('additional_raid'),
        items: this.booleanSelectList,
      },
      {
        type: 'select',
        key: 'oldKernelVersion',
        name: i18next.t('older_kernel_version'),
        items: this.booleanSelectList,
      });
    }

    return (
      <Fragment>
        <ModalHeader text={i18next.t('licenses')} />
        <LicenseToolbar
          tableId="selectLicenses"
          filterFields={filterFields}
          updateLicensesDataSignal={updateLicensesDataSignal}
          signalAttributes={signalAttributes}
        />
        <TableAsync
          className="table_modal"
          id="selectLicenses"
          items={items}
          filterFields={filterFields}
          onClickRow={this.onSelectLicense}
          cells={cells}
          pagination={pagination}
          tableGetSignal={updateLicensesDataSignal}
          signalAttributes={signalAttributes}
          loading={this.state.loading}
        />
      </Fragment>
    );
  }
}
