import React from 'react';
import Chip from 'components/chip';

export default function FunctionalityCell({ className, functionalities, value }) {
  const result = [];

  value.forEach((functionalityId) => {
    const functionality = functionalities
      .find(entry => (entry.get('id') === Number(functionalityId)));

    const name = functionality.get('name');

    // TODO: можно ли совмещать chip_none chip-list__item(блок и элемент блока)
    result.push(
      <Chip
        key={functionalityId}
        className="chip_none chip-list__item"
        title={name}
      >
        {name}
      </Chip>
    );
  });

  return (
    <div className={className}>
      <div className="chip-list">
        {result}
      </div>
    </div>
  );
}
