import React from 'react';
import { Link } from 'react-router-dom';
import TableCell from './cell';

export default function LinkCell({ value, className }) {
  return (
    <TableCell className={className}>
      <Link to={value.link}>{value.text}</Link>
    </TableCell>
  );
}
